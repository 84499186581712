import { GeopathSegment } from './impressions';
import { Organization } from './organization';
import { MediaUnit } from './sign';
import { User } from './user';

export interface ProposalColumn {
  name: string;
  column: string;
  impressionsDataField?: string;
  periodDays?: number;
  targetSegment?: GeopathSegment;
  data?: string;
  verified?: boolean;
  derivedSpotData?: boolean;
}

export interface SortColumn {
  column: string;
  group: boolean;
}

export interface SignExport {
  id: string;
  name: string;
  targetSegment?: { id: number; name: string }; // For geopath query
  periodDays?: number; // For geopath query
  derivedSpotData?: boolean;
  created: string;
  modified: string;
  columns: Array<ProposalColumn>;
}

export interface ProposalHistory {
  action: 'addsigns' | 'removesigns' | 'save' | 'processavails';
  date: string;
  by: {
    id: string;
    firstName: string;
    lastName: string;
  };
  units?: MediaUnit[];
  signOwner?: {
    id: string;
    name: string;
  };
}

export interface ProposalArtwork {
  url: string;
  color: string;
}

export type ProposalState =
  | 'InventoryEvaluation'
  | 'ProposalCreation'
  | 'HoldNegotiation'
  | 'ContractNegotiation'
  | 'DesignNegotiation'
  | 'UnderContract'
  | 'ContractComplete'
  | 'Cancelled';

export interface ProposalTask {
  state: ProposalState;
  task: string;
  completed?: string;
}

interface ProposalHoldBase {
  unitId: string;
  unit?: MediaUnit;
  rate: number;
  feeRate: number;
  bonusPeriods: number;
  installationCost: number;
  productionCost: number;
  internalProduction: boolean;
  start: string;
  end: string;
  notes: string;
}

export interface ProposalHold extends ProposalHoldBase {
  expiration: string;
  state?: 'added' | 'held' | 'rejected';
}

export interface ProposalContract extends ProposalHoldBase {
  state?: 'added' | 'accepted' | 'rejected';
}

export interface ProposalOrgContract {
  acceptedOn: string;
  acceptedBy: Partial<User>;
}

export interface Proposal {
  id: string;
  orgId: string;
  agency: Partial<Organization>;
  client: string;
  clientId: string;
  anonymizeClient: boolean;
  owner: Partial<User>;
  location: string;
  name: string;
  start: string;
  end: string;
  created: number;
  modified: number;
  // Fields below are stored on the server in details
  iconUrl: string;
  description: string;
  state: ProposalState;
  units: Array<MediaUnit>;
  holds: Array<ProposalHold>;
  contracts: Array<ProposalContract>;
  columns: Array<ProposalColumn>;
  sort: Array<SortColumn>;
  query: any;
  history: Array<ProposalHistory>;
  artwork: Array<ProposalArtwork>;
  tasks: Array<ProposalTask>;
  photoSheet: {
    url: string;
    created: string;
    googleSheetId: string;
  };
  dueDate: string;
  dueDateTimezone: string;
  cancelReason: string;
}

export interface MessageUnit {
  id: string;
  available: 'unknown' | 'yes' | 'no' | 'partial';
  start?: string;
  end?: string;
}

export interface MessageAvails {
  cc: string;
  units: MessageUnit[];
}

export interface Message {
  id: string;
  modified: string;
  recipient: Organization;
  type: 'avails' | 'holds' | 'contracts';
  notes: string;
  parent: Message;
  replies: Message[];
  avails?: MessageAvails;
  holds?: ProposalHold[];
  contracts?: ProposalContract[];
  contract?: ProposalOrgContract;
}
