import { Directive, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

@Directive({
  selector: '[coreDialogClose]',
  exportAs: 'coreDialogClose',
})
export class DialogCloseDirective {
  @Input('coreDialogClose') dialogResult: any;

  constructor(private dialog: MatDialog) {}

  @HostListener('click')
  clickClose() {
    const last = _.last(this.dialog.openDialogs);
    if (last) {
      last.close(this.dialogResult);
    } else {
      console.error('coreDialogClose used without an open dialog');
    }
  }
}
