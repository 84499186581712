export interface User {
  id: string;
  email: string;
  email_verified: string;
  phone: string;
  phoneFormated?: string;
  first_name: string;
  last_name: string;
  terms_accepted: string;
  timezone: string;
  preferences: any;
  is_reseller: boolean;
  is_superuser: boolean;
  roles?: string[];
}
