export const environment = {
  production: false,
  API_URL: 'https://bbhoarde-staging.herokuapp.com',
  OPERATOR_URL: 'https://operator.blipbillboardsstage.com',
  STATIC_MAPS_KEY: 'AIzaSyAuhJgIG4Bbu3VR3upqCdEoyq4nMsCfsGA',
  TAG_MANAGER_ID: 'GTM-T9DZ7B9',
  PHOTOSHEET_URL: 'https://photosheet.dev.blipbillboards.com/gen/',
  PHOTOSHEET_COMPILE_URL: 'https://9jrmnmr3ig.execute-api.us-east-1.amazonaws.com/dev/convert',
  PHOTOSHEET_STATUS_URL: 'https://9jrmnmr3ig.execute-api.us-east-1.amazonaws.com/dev/getconversionstatus',
  CORNER_PIN_URL: 'https://9jrmnmr3ig.execute-api.us-east-1.amazonaws.com/dev/pinned.jpg',
  GAPI_CLIENT_ID: '200869559120-k1uaofohea7mc9kvrofvk97e07qms115.apps.googleusercontent.com',
};
