export class Environment {
  // Url of API
  public API_URL: string;
  public OPERATOR_URL: string;
  public PHOTOSHEET_URL: string;
  public PHOTOSHEET_COMPILE_URL: string;
  public PHOTOSHEET_STATUS_URL: string;
  public CORNER_PIN_URL: string;
  // only add this if default adkom host is not an option such as localhost
  PHOTOSHEET_ASSETS_BASE_URL?: string;
}
