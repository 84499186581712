import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Organization } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private api: ApiService) {}

  public get(orgId: string): Observable<Organization> {
    if (_.isEmpty(orgId)) {
      return throwError('Missing orgId');
    }
    return this.api.get(`/api/account/organization/${orgId}`);
  }

  public create(org: Partial<Organization>): Observable<Organization> {
    return this.api.post('/api/account/organization/', org);
  }

  public query(params: any): Observable<Organization[]> {
    return this.api.get('/api/account/organization/query', params);
  }
}
