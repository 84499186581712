<div class="formHolder">
  <mat-form-field>
    <mat-label i18n>Segment</mat-label>
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onTouched()" />
    <button mat-icon-button matSuffix aria-label="Clear segment" (click)="clear()" i18n-aria-label type="button">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="display" [panelWidth]="300">
  <mat-option *ngFor="let option of filteredSegments | async" [value]="option" [matTooltip]="option.name">
    <span class="code">{{ option.id }}</span>
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
