import { RightsService } from '@adkom/core';
import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Component({
  selector: 'adkom-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
  public activeClass = 'active';
  public orgId;
  private destroyed = new Subject();
  constructor(@Optional() public app: AppComponent, public rights: RightsService, private router: Router) {}

  ngOnInit() {
    this.router.events
      .pipe(
        takeUntil(this.destroyed),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.orgId = _.first(_.split(_.trim(event.url, '/'), '/'));
      });
  }

  ngOnDestroy() {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
