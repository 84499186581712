import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'adkom-navigation-error',
  templateUrl: './navigation-error.component.html',
  styleUrls: ['./navigation-error.component.scss'],
})
export class NavigationErrorComponent implements OnInit {
  public error: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.error = this.route.snapshot.queryParamMap.get('error');
  }
}
