import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Environment, PageInfo } from '../models';
import { MediaUnit, MediaUnitColumn } from '../models/sign';
import { TranslateService } from './translate.service';

@Injectable({
  providedIn: 'root',
})
export class SignService {
  private readonly inData = new Set([
    'address',
    'cancellationPolicy',
    'cycleType',
    'dailyImpressions',
    'description',
    'environment',
    'exclusions',
    'extensions',
    'externalId',
    'faceCount',
    'illuminated',
    'installationCost',
    'location',
    'market',
    'mediaType',
    'nearestPOI',
    'nearestPOIAddress',
    'nearestPOIDistance',
    'pointsOfInterest',
    'productionContact',
    'productionCost',
    'productionLeadTime',
    'productionShipping',
    'recommendedMaterial',
    'requiredApproval',
    'specifications',
  ]);

  // Only return the SignOps not to general public
  private readonly inDataPrivate = new Set([
    'adkomMedia',
    'adkomMediaCPM',
    'adkomRate',
    'adkomRateCPM',
    'fourWeekMinimum',
    'fourWeekRateCard',
  ]);
  private readonly virtualColumns = new Set(['proposalPOIName', 'proposalPOIDistance']);

  public readonly impressionsDataFields = [
    'spotId',
    'frameId',
    'plantFrameId',
    'spotShareOfVoice',
    'defaultDistribution',
    'geopathExactMatch',
    'sourceId',
    'sourceName',
    'categoryId',
    'categoryName',
    'subcategoryId',
    'subcategoryName',
    'segmentId',
    'segmentName',
    'baseImpressions',
    'targetImpressions',
    'trp',
    'freqAvg',
    'reachPct',
    'reachNet',
    'indexCompTarget',
    'avgImpressionsPerFlip',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
  ];

  private cachedFields: Observable<any>;

  constructor(private apollo: Apollo, private translate: TranslateService, private environment: Environment) {}

  private filterColumns(columns: string[]): Observable<string[]> {
    // Map fields to be backwards compatible with exports that are already defined
    const mappedFields = {
      lat: 'lat:latitude',
      lon: 'lon:longitude',
      height: 'height:pixelHeight',
      width: 'width:pixelWidth',
      adkomEnabled: 'adkomEnabled:enabled(app:"adkom")',
      adomniEnabled: 'adomniEnabled:enabled(app:"adomni")',
      vistarEnabled: 'vistarEnabled:enabled(app:"vistar")',
      blipMarketplaceEnabled: 'blipMarketplaceEnabled:enabled(app:"blip")',
      boardWidth: 'boardWidth:physicalWidth',
      boardHeight: 'boardHeight:physicalHeight',
      exclusions: 'exclusions',
      illuminated: 'illuminated',
    };

    return this.getFields().pipe(
      map((fields) => {
        const existing = _.keyBy(fields, 'name');
        return _.map(columns, (column) => {
          const key = _.trim(_.first(_.split(column, /\(|\{/)));
          if (key.startsWith('geopath_')) {
            return this.formatImpressionsDataField(key, column);
          }
          if (key.startsWith('internal_')) {
            return this.formatInternalField(key);
          }
          if (existing[key]) {
            return column;
          }
          if (mappedFields[column]) {
            return mappedFields[column];
          }
          if (column.includes(':')) {
            return column;
          }
          console.warn('Trying to query non-existing column', column);
          return '';
        });
      })
    );
  }

  private formatImpressionsDataField(key: string, column: string) {
    const parts = key.split('_');
    const field = column.match(/[^{}]+(?=})/g)[0].trim();
    const result =
      `${key}:impressionsData(targetSegmentId: ${parts[1]}, ` +
      `periodDays: ${parts[2]}, derivedSpotData: ${Boolean(Number(parts[3]))}) { ${field} }`;
    return result;
  }

  private formatInternalField(key: string) {
    const parts = key.split('_', 2);
    return `${key}:${parts[0]}(key:"${parts[1]}")`;
  }

  public listAll(columns: Array<string>, query = {}): Observable<Array<MediaUnit>> {
    const pageSize = 100;
    return new Observable<Array<MediaUnit>>((sub) => {
      const list = [];
      const done = new BehaviorSubject<string>(undefined);
      done
        .pipe(
          switchMap((after) => this.listWithPageInfo(columns, query, pageSize, undefined, after, 'ownerName')),
          tap((result) => {
            list.push(...result.units);
            if (result.pageInfo.hasNextPage) {
              done.next(result.pageInfo.endCursor);
            } else {
              done.complete();
            }
          })
        )
        .subscribe({
          complete: () => {
            sub.next(list);
            sub.complete();
          },
          error: (err) => sub.error(err),
        });
    });
  }

  public listWithPageInfo(
    columns: Array<string>,
    query: any = {},
    pageSize = 100,
    before?: string,
    after?: string,
    order?: string
  ): Observable<{
    units: MediaUnit[];
    pageInfo: PageInfo;
    totalCount: number;
  }> {
    return this.filterColumns(columns).pipe(
      switchMap((filtered) => {
        const gqlQuery = gql`
            query Signs($first: Int, $last: Int, $after: String, $before: String, $orgId: ID, $dmas: [ID],
              $enabled: [String], $order: String, $ids: [ID], $postalCodes: [String], $cities: [String],
              $environments: [String], $mediaTypes: [String], $read: String,
              $aspectRatio: Float, $aspectRatioGte: Float, $aspectRatioLte: Float,
              $physicalHeight: Float, $physicalHeightGte: Float, $physicalHeightLte: Float,
              $physicalWidth: Float, $physicalWidthGte: Float, $physicalWidthLte: Float,
              $hasPhoto: Boolean, $isGeopathAudited: Boolean,
              $provinces: [String], $text: String, $techTypes: [String], $storedQuery: ID, $pois: [POIInputType],
              $availability: [AvailabilityInputType], $impressions: [ImpressionsInputType], $exclusions: [String],
              $includeOrgs: [ID], $excludeOrgs: [ID])
            {
              allUnits(first: $first, last: $last, after: $after, before: $before, orgId: $orgId, dmaIn: $dmas,
                enabled: $enabled, orderBy: $order, idIn: $ids, postalCodeIn: $postalCodes, cityIn: $cities,
                environmentIn: $environments, mediaTypeIn: $mediaTypes, read: $read,
                exclusionIn: $exclusions, orgIdIn: $includeOrgs, orgIdNotIn: $excludeOrgs,
                hasPhoto: $hasPhoto, isGeopathAudited: $isGeopathAudited,
                aspectRatio: $aspectRatio, aspectRatioGte: $aspectRatioGte, aspectRatioLte: $aspectRatioLte,
                physicalHeight: $physicalHeight, physicalHeightGte: $physicalHeightGte , physicalHeightLte: $physicalHeightLte,
                physicalWidth: $physicalWidth, physicalWidthGte: $physicalWidthGte, physicalWidthLte: $physicalWidthLte,
                provinceIn: $provinces, text: $text, techTypeIn: $techTypes, location: $storedQuery, poiIn: $pois,
                availability: $availability, impressions: $impressions)
              {
                totalCount
                pageInfo {
                  hasNextPage
                  endCursor
                  startCursor
                }
                edges { node { ${filtered.join(' ')} } }
              }
            }`;
        const variables = _.omitBy(
          _.assign(
            {
              first: before ? undefined : pageSize,
              last: !before ? undefined : pageSize,
              after,
              before,
              order,
            },
            query
          ),
          _.isNil
        );
        return this.apollo.query({
          query: gqlQuery,
          errorPolicy: 'all',
          variables,
        });
      }),
      map((result) => {
        const allUnits = _.get(result, 'data.allUnits', {});
        const units = _.map(allUnits.edges, (unit) => this.fromServer(unit));
        return {
          units,
          totalCount: allUnits.totalCount,
          pageInfo: allUnits.pageInfo,
        };
      })
    );
  }

  public getMultiple(columns: string[], signIds: string[]): Observable<Array<MediaUnit>> {
    const pageSize = 100;
    if (_.isEmpty(signIds)) {
      return of([]);
    }
    return new Observable<Array<MediaUnit>>((sub) => {
      const list = [];
      const done = new BehaviorSubject<boolean>(true);
      done
        .pipe(
          switchMap(() => this.listWithPageInfo(columns, { ids: _.take(signIds, pageSize) })),
          tap((result) => {
            signIds = _.drop(signIds, pageSize);
            list.push(...result.units);
            if (!_.isEmpty(signIds)) {
              done.next(true);
            } else {
              done.complete();
            }
          })
        )
        .subscribe({
          complete: () => {
            sub.next(list);
            sub.complete();
          },
          error: (err) => sub.error(err),
        });
    });
  }

  public get(columns: string[], id: string): Observable<MediaUnit> {
    return this.apollo
      .query({
        query: gql`
      query unit($id: ID!) {
        unit(id: $id) { ${columns.join(' ')} }
      }`,
        variables: { id },
      })
      .pipe(map((result) => this.fromServer({ node: _.get(result, 'data.unit', {}) })));
  }

  private fromServer(serverObject: any): MediaUnit {
    const update = (photo: any, key: string) => {
      if (_.startsWith(_.get(photo, key), '/')) {
        _.set(photo, key, `${this.environment.API_URL}${_.get(photo, key)}`);
      }
    };
    const unit = serverObject.node;
    update(unit.photo, 'url');
    update(unit.photo, 'thumbnailUrl');
    return serverObject.node;
  }

  private getFields(): Observable<{ name: string; description: string; type: { name: string } }[]> {
    if (!this.cachedFields) {
      this.cachedFields = this.apollo
        .query({
          query: gql`
            {
              __type(name: "UnitNode") {
                name
                fields {
                  name
                  description
                  type {
                    name
                  }
                }
              }
            }
          `,
        })
        .pipe(
          map((result) => _.get(result, 'data.__type.fields', [])),
          shareReplay()
        );
    }
    return this.cachedFields;
  }

  public getColumns(): Observable<MediaUnitColumn[]> {
    const types = {
      adkomRate: 'Money',
      adkomMedia: 'Money',
      fourWeekRateCard: 'Money',
    };
    return this.getFields().pipe(
      map((result) => {
        const fields = _.filter(result, (field) => {
          field.type.name = types[field.name] || field.type.name;
          // Filter fields we don't want
          return ![
            'sign',
            'photos',
            'photo',
            'organization',
            'enabled',
            'availability',
            'gpsLatitude',
            'gpsLongitude',
            'pointsOfInterest',
            'dma',
            'impressionsData',
            'internal',
            'link',
            'links',
            'exclusions',
          ].includes(field.name);
        });
        // Sort
        const partialOrder = ['id'];

        fields.push(
          ...[
            {
              name: 'adkomEnabled',
              description: 'Adkom unit',
              type: { name: 'Boolean' },
            },
            {
              name: 'blipMarketplaceEnabled',
              description: 'Blip unit',
              type: { name: 'Boolean' },
            },
          ]
        );

        const columns: Array<MediaUnitColumn> = _.sortBy(
          _.map(
            fields,
            (field) =>
              <any>{
                name: field.name,
                title: this.translate.s(field.name, 'column'),
                type: field.type.name,
                data: field['data'],
              }
          ),
          (field) => {
            const index = _.indexOf(partialOrder, field.name);
            return [index < 0 ? partialOrder.length : index, field.title];
          }
        );
        this.insertColumn(columns, {
          title: this.translate.s('photo', 'column'),
          name: 'photo { url }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('organization', 'column'),
          name: 'organization { name }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('pointsOfInterest', 'column'),
          name: 'pointsOfInterest { category name distance latitude longitude }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('dmaId', 'column'),
          name: 'dma { id }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('dmaName', 'column'),
          name: 'dma { name }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('proposalPOIDistance', 'column'),
          name: 'proposalPOIDistance',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('proposalPOIName', 'column'),
          name: 'proposalPOIName',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('link', 'column'),
          name: 'link { url }',
          type: 'String',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('adkomRateComputed', 'column'),
          name: 'adkomRateComputed:adkomRate(computeIfEmpty:true)',
          type: 'Money',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('adkomMediaBest', 'column'),
          name: 'adkomMediaBest:adkomMedia(agencyId:proposal.agency.id, computeIfEmpty:true)',
          type: 'Money',
          data: '',
        });
        this.insertColumn(columns, {
          title: this.translate.s('adkomMediaComputed', 'column'),
          name: 'adkomMediaComputed:adkomMedia(computeIfEmpty:true)',
          type: 'Money',
          data: '',
        });

        return columns;
      })
    );
  }

  private insertColumn(columns: MediaUnitColumn[], column: MediaUnitColumn) {
    const insertAt = _.sortedIndexBy(columns, column, 'title');
    columns.splice(insertAt, 0, column);
  }
}
