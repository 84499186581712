import { MediaLink } from './media-link';
import { Organization } from './organization';

export interface MediaUnit {
  id: string;
  name: string;
  techType: 'STATIC' | 'DIGITAL' | 'TRIVISION';
  sign?: Sign;
  organization?: Organization;
  description: string;
  location: string;
  displayName: string;
  ownerName: string;
  facing: string;
  links?: MediaLink[];
  latitude: number;
  longitude: number;
  boardHeight: number;
  boardWidth: number;
  height: number;
  width: number;
  read: string;
  timezone: string;
  photo: { id: string; url: string };
  city: string;
  province: string;
  dailyImpressions: number;
  availability?: { status: 'full' | 'partial' | 'none' };

  market: string;
  mediaType: string;
  productionCost: number;
  productionShipping: string;
  productionLeadTime: string;
  productionContact: string;
  specifications: string;

  exclusions: string;
  nearestPOI: string;
  nearestPOIAddress: string;
  /** distance in miles */
  nearestPOIDistance: number;
  pointsOfInterest: POICategories;
  recommendedMaterial: string;
  requiredApproval: string;
  extensions: string;

  // Private data
  fourWeekRateCard: number;
  fourWeekMinimum: number;
  adkomRate: number;
  adkomMedia: number;
  adkomRateCPM: number;
  adkomMediaCPM: number;
}

export interface Sign {
  audited: boolean;
  average_dwell_time: string;
  enabled: string;
  flip_duration: number;
  freezes: string;
  freeze_time: string;
  height: number;
  impression_frequency: string;
  live_view: string;
  live_view_password: string;
  live_view_username: string;
  manufacturer: string | null;
  ordered: boolean;
  inactive_slots: number[];
  refresh_timestamp: string | number;
  thaw_time: string;
  total_slots: number;
  width: number;
}

export interface MediaUnitColumn {
  name: string;
  title: string;
  type: 'Float' | 'Int' | 'Boolean' | 'String' | 'Money';
  data?: string;
}

export interface POICategories {
  Airport: Array<PointOfInterest>;
  Aquarium: Array<PointOfInterest>;
  'Big Box Store': Array<PointOfInterest>;
  City: Array<PointOfInterest>;
  'Clothing Store': Array<PointOfInterest>;
  'Electronics Store': Array<PointOfInterest>;
  Food: Array<PointOfInterest>;
  Hospital: Array<PointOfInterest>;
  Intersection: Array<PointOfInterest>;
  'Light Rail Station': Array<PointOfInterest>;
  'Metro Station': Array<PointOfInterest>;
  'Movie Theater': Array<PointOfInterest>;
  Museum: Array<PointOfInterest>;
  Pharmacy: Array<PointOfInterest>;
  'Shopping Mall': Array<PointOfInterest>;
  Stadium: Array<PointOfInterest>;
  Supermarket: Array<PointOfInterest>;
  'Theme Park': Array<PointOfInterest>;
  'Train Station': Array<PointOfInterest>;
  University: Array<PointOfInterest>;
  'Warehouse Store': Array<PointOfInterest>;
}
export interface PointOfInterest {
  distance_in_miles: number;
  lat: number;
  lon: number;
  name: string;
  category?: string;
}
