import { Inject, Injectable, InjectionToken } from '@angular/core';
import * as _ from 'lodash';

// istanbul ignore next
function getLocalStorage() {
  try {
    return localStorage ? localStorage : null;
  } catch (e) {
    alert('Please enable cookies for this website');
    return null;
  }
}

export const LOCAL_STORAGE = new InjectionToken('LOCAL_STORAGE', {
  providedIn: 'root',
  factory: getLocalStorage,
});

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private localStorage) {}

  getString(key: string, defaultValue = ''): string {
    const value = this.getItemRaw(key);
    return _.isNull(value) ? defaultValue : value;
  }

  getNumber(key: string, defaultValue = 0): number {
    const value = this.getItemRaw(key);
    if (!_.isNull(value)) {
      const number = Number.parseFloat(value);
      return _.isFinite(number) ? number : defaultValue;
    }
    return defaultValue;
  }

  getBoolean(key: string, defaultValue = false): boolean {
    const value = this.getItemRaw(key);
    if (!_.isNull(value)) {
      return value === 'true';
    }
    return defaultValue;
  }

  getObject(key: string, defaultValue: any = null): any {
    try {
      const value = this.getItemRaw(key);
      if (!_.isNull(value)) {
        return JSON.parse(value);
      }
    } catch {
      return defaultValue;
    }
    return defaultValue;
  }

  setItem(key: string, value: string | number | boolean | any): void {
    try {
      this.localStorage.setItem(key, _.isString(value) ? value : JSON.stringify(value));
    } catch {
      // Ignore
    }
  }

  removeItem(key: string): void {
    try {
      this.localStorage.removeItem(key);
    } catch {
      // Ignore
    }
  }

  private getItemRaw(key: string): string | null {
    try {
      return this.localStorage.getItem(key);
    } catch {
      return null;
    }
  }
}
