import { Directive, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ContainerService, ContainerType } from '../services';

@Directive({
  selector: '[coreContainer]',
})
export class ContainerDirective implements OnInit, OnDestroy {
  @Input() coreContainer: ContainerType;

  constructor(private viewContainer: ViewContainerRef, private containerService: ContainerService) {}

  ngOnInit() {
    this.containerService.registerContainer(this.coreContainer, this.viewContainer);
  }

  ngOnDestroy() {
    this.containerService.unregisterContainer(this.coreContainer);
  }
}
