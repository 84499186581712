<mat-sidenav-container>
  <mat-sidenav mode="over">
    <adkom-navigation></adkom-navigation>
  </mat-sidenav>
  <mat-sidenav-content class="full-page">
    <adkom-header></adkom-header>
    <div class="outlet">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
