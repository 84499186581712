import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services';

@Pipe({
  name: 'roadSide',
})
export class RoadSidePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(side: 'R' | 'L'): string {
    if (!side) {
      return '';
    }
    return this.translate.s(`roadside_${side}`, 'pipe');
  }
}
