export interface PageInfo {
  /** When paginating forwards, are there more items? */
  hasNextPage: boolean;

  /** When paginating backwards, are there more items? */
  hasPreviousPage: boolean;

  /** When paginating backwards, the cursor to continue. */
  startCursor?: string;

  /** When paginating forwards, the cursor to continue. */
  endCursor?: string;
}
