<div class="formHolder">
  <mat-form-field>
    <mat-label i18n>Locations</mat-label>
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onTouched()" />
    <div class="actions" matSuffix>
      <button mat-icon-button *ngIf="control.value" aria-label="Clear organization" (click)="clear()" i18n-aria-label>
        <mat-icon>clear</mat-icon>
      </button>
      <button mat-icon-button *ngIf="last" (click)="$event.stopPropagation(); edit.next(last)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!last" (click)="$event.stopPropagation(); add.next(true)">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </mat-form-field>
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
  <mat-option *ngFor="let option of filteredStoredQueries | async" [value]="option">
    <span class="code">{{ option.id }}</span>
    {{ option.name }}
  </mat-option>
</mat-autocomplete>
