<div #strings class="strings">
  <span id="id" i18n>Id</span>
  <span id="name" i18n>Name</span>
  <span id="displayName" i18n>Display name</span>
  <span id="latitude" i18n>Latitude</span>
  <span id="longitude" i18n>Longitude</span>
  <span id="pixelHeight" i18n>Pixel height</span>
  <span id="pixelWidth" i18n>Pixel width</span>
  <span id="physicalHeight" i18n>Board height</span>
  <span id="physicalWidth" i18n>Board width</span>
  <span id="address" i18n>Address</span>
  <span id="city" i18n>City</span>
  <span id="province" i18n>State</span>
  <span id="description" i18n>Description</span>
  <span id="image" i18n>Image</span>
  <span id="imageAlways" i18n>Image (always)</span>
  <span id="manufacturer" i18n>Manufacturer</span>
  <span id="facing" i18n>Facing</span>
  <span id="read" i18n>Read</span>
  <span id="ordered" i18n>Use slots</span>
  <span id="techType" i18n>Technology type</span>
  <span id="totalSlots" i18n>Number of slots</span>
  <span id="flipDuration" i18n>Flip duration</span>
  <span id="postalCode" i18n>Zip code</span>
  <span id="blipMarketplaceEnabled" i18n>Marketplace</span>
  <span id="adkomEnabled" i18n>Adkom</span>
  <span id="adomniEnabled" i18n>Adomni</span>
  <span id="vistarEnabled" i18n>Vistar</span>
  <span id="geopathId" i18n>Unit Geopath Id</span>
  <span id="timezone" i18n>Timezone</span>
  <span id="cycleType" i18n>Cycle type</span>
  <span id="dailyImpressions" i18n>Daily impressions</span>
  <span id="description" i18n>Description</span>
  <span id="environment" i18n>Environment</span>
  <span id="faceCount" i18n>Face Count</span>
  <span id="fourWeekPrice" i18n>Four week price</span>
  <span id="fourWeekNegotiated" i18n>Four week negotiated</span>
  <span id="freezes" i18n>Freezes</span>
  <span id="freezeTime" i18n>Freeze time</span>
  <span id="height" i18n>Height</span>
  <span id="illuminated" i18n>Illuminated</span>
  <span id="installationCost" i18n>Installation cost</span>
  <span id="internalNotes" i18n>Internal notes</span>
  <span id="link" i18n>Link</span>
  <span id="liveView" i18n>Live view</span>
  <span id="liveViewPassword" i18n>Live view password</span>
  <span id="liveViewUsername" i18n>Live view username</span>
  <span id="location" i18n>Location</span>
  <span id="manufacturer" i18n>Manufacturer</span>
  <span id="market" i18n>Market</span>
  <span id="mediaType" i18n>Media type</span>
  <span id="size" i18n>Size (H x W)</span>
  <span id="specifications" i18n>Specifications</span>
  <span id="thawTime" i18n>Thaw time</span>
  <span id="width" i18n>Width</span>
  <span id="externalId" i18n>External Id</span>
  <span id="weeklyImpressions" i18n>Weekly impressions</span>
  <span id="fourWeekRateCard" i18n>Four week rate</span>
  <span id="fourWeekMinimum" i18n>Four week minimum</span>
  <span id="adkomRate" i18n>Adkom rate (raw)</span>
  <span id="adkomMediaBest" i18n>Adkom media (best)</span>
  <span id="adkomRateComputed" i18n>Adkom rate (computed)</span>
  <span id="adkomMedia" i18n>Adkom media (raw)</span>
  <span id="adkomMediaComputed" i18n>Adkom media (computed)</span>
  <span id="adkomRateCPM" i18n>Adkom rate CPM</span>
  <span id="adkomMediaCPM" i18n>Adkom media CPM</span>
  <span id="productionCost" i18n>Production cost</span>
  <span id="productionShipping" i18n>Production shipping</span>
  <span id="productionLeadTime" i18n>Production lead time</span>
  <span id="productionContact" i18n>Production contact</span>
  <span id="cancellationPolicy" i18n>Cancellation policy</span>
  <span id="exclusions" i18n>Exclusions</span>
  <span id="nearestPOI" i18n>Nearest POI</span>
  <span id="nearestPOIAddress" i18n>POI Address</span>
  <span id="nearestPOIDistance" i18n>Distance from POI (miles)</span>
  <span id="proposalPOIName" i18n>POI name</span>
  <span id="proposalPOIDistance" i18n>POI distance (miles)</span>
  <span id="pointsOfInterest" i18n>Points of Interest</span>
  <span id="recommendedMaterial" i18n>Recommended material</span>
  <span id="requiredApproval" i18n>Creative approval required</span>
  <span id="extensions" i18n>Allows extensions</span>
  <span id="photo" i18n>Photo</span>
  <span id="organization" i18n>Organization</span>
  <span id="guid" i18n>GUID</span>
  <span id="organizationId" i18n>Organization Id</span>
  <span id="ownerName" i18n>Owner name</span>
  <span id="dmaName" i18n>DMA name</span>
  <span id="dmaId" i18n>DMA Id</span>
  <span id="aspectRatio" i18n>Aspect ratio</span>
  <span id="exclusion" i18n>Exclusions</span>
  <span id="isIlluminated" i18n>Illuminated</span>
  <span id="illuminatedHours" i18n>Hours illuminated</span>
  <span id="illuminatedSchedule" i18n>Illumination schedule</span>
  <span id="relativePosition" i18n>Relative position</span>
  <span id="motion" i18n>Motion</span>
  <span id="alternateFlipDurations" i18n>Alternate flip durations</span>
  <span id="extensionAllow" i18n>Allow extension</span>
  <span id="extensionMaxHeight" i18n>Extension max height</span>
  <span id="extensionMaxWidth" i18n>Extension max width</span>
  <span id="extensionCost" i18n>Extension cost</span>
  <span id="embellishmentAllow" i18n>Allow 3D embellishment</span>
  <span id="embellishmentMaxHeight" i18n>Embellishment max height</span>
  <span id="embellishmentMaxWidth" i18n>Embellishment max width</span>
  <span id="embellishmentCost" i18n>Embellishment cost</span>

  <span id="Yes" i18n>Yes</span>
  <span id="No" i18n>No</span>
</div>
