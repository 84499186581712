import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizationPreferences, SESSION, Session, UserPreferences } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {
  constructor(private apiService: ApiService) {}

  public getUser(userId: string): Observable<UserPreferences> {
    return this.apiService
      .get(`/api/account/preferences/user/${userId}`)
      .pipe(map((serverPreferences) => this.defaultUser(serverPreferences)));
  }

  public updateUser(userId: string, preferences: Partial<UserPreferences>): Observable<UserPreferences> {
    return this.apiService
      .put(`/api/account/preferences/user/${userId}`, preferences)
      .pipe(map((serverPreferences) => this.defaultUser(serverPreferences)));
  }

  public getOrganization(orgId: string): Observable<OrganizationPreferences> {
    return this.apiService
      .get(`/api/account/preferences/org/${orgId}`)
      .pipe(map((serverPreferences) => this.defaultOrganization(serverPreferences)));
  }

  public updateOrganization(
    orgId: string,
    preferences: Partial<OrganizationPreferences>
  ): Observable<OrganizationPreferences> {
    return this.apiService
      .put(`/api/account/preferences/org/${orgId}`, preferences)
      .pipe(map((serverPreferences) => this.defaultOrganization(serverPreferences)));
  }

  private defaultUser(preferences: Partial<UserPreferences>): UserPreferences {
    return _.defaults(preferences, {
      adkomDefaultExport: '',
    });
  }
  private defaultOrganization(preferences: Partial<OrganizationPreferences>): OrganizationPreferences {
    return _.defaults(preferences, {
      adkomExports: [],
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrgPrefResolver implements Resolve<OrganizationPreferences> {
  constructor(private preferences: PreferencesService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<OrganizationPreferences> {
    const orgId = route.paramMap.get('orgId');
    return this.preferences.getOrganization(orgId);
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserPrefResolver implements Resolve<UserPreferences> {
  constructor(private preferences: PreferencesService, @Inject(SESSION) private session: Session) {}
  resolve(): Observable<UserPreferences> {
    const userId = this.session.user.id;
    return this.preferences.getUser(userId);
  }
}

@Injectable({
  providedIn: 'root',
})
export class UserResolver implements Resolve<{ id: string; firstName: string; lastName: string }> {
  constructor(private apollo: Apollo) {}
  resolve(): Observable<{ id: string; firstName: string; lastName: string }> {
    return this.apollo
      .query({
        query: gql`
          query {
            me {
              id
              firstName
              lastName
            }
          }
        `,
      })
      .pipe(map((results) => _.get(results, 'data.me')));
  }
}
