import { Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ContainerService, ContainerType } from '../services';

@Directive({
  selector: '[coreTemplate]',
})
export class TemplateDirective implements OnInit, OnDestroy {
  @Input() coreTemplate: ContainerType;

  constructor(private template: TemplateRef<any>, private containerService: ContainerService) {}

  ngOnInit() {
    this.containerService.setUI(this.coreTemplate, this.template);
  }

  ngOnDestroy() {
    this.containerService.clearUI(this.coreTemplate);
  }
}
