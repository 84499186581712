import { formatNumber } from '@angular/common';

export function formatFeet(locale: string, feet: number): string {
  const inches = feet * 12;
  if (inches < 12) {
    return `${formatNumber(inches, locale, '1.0-1')}"`;
  }
  return (
    `${formatNumber(Math.floor(inches / 12), locale, '1.0-0')}'` + ` ${formatNumber(inches % 12, locale, '1.0-1')}"`
  );
}

export function formatInches(locale: string, feet: number): string {
  const inches = feet * 12;
  return `${formatNumber(inches, locale, '1.0-1')}"`;
}

export function formatMeters(locale: string, feet: number): string {
  return formatNumber(feet * 0.3048, locale, '1.0-3');
}

export function formatLat(locale: string, value: number): string {
  return latLong(locale, value, ['N', 'S']);
}

export function formatLon(locale: string, value: number): string {
  return latLong(locale, value, ['E', 'W']);
}

function latLong(locale: string, dec: number, direction: string[]): string {
  const dir = direction[dec < 0 ? 1 : 0];
  dec = Math.abs(dec);
  const d = Math.floor(dec);
  const m = Math.floor(60 * (dec - d));
  const s = 3600 * (dec - d) - 60 * m;
  return `${d}° ${m}' ${formatNumber(s, locale, '1.0-3')}" ${dir}`;
}
