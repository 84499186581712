import { ApiService, Organization, RightsService } from '@adkom/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationResolver implements Resolve<Organization> {
  constructor(private api: ApiService, private rights: RightsService, private apollo: Apollo) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Organization> {
    const orgId = route.paramMap.get('orgId');
    if (_.isEmpty(orgId)) {
      return throwError('Missing orgId in route');
    }
    const org = this.rights.getOrgById(orgId);
    return forkJoin([org ? of(org) : this.api.get(`/api/account/organization/${orgId}/`), this.getNewId(orgId)]).pipe(
      map(([orgData, newId]) => _.assign({ newId }, orgData))
    );
  }

  private getNewId(code: string): Observable<string> {
    return this.apollo
      .query({
        query: gql`
          query toId($code: ID!) {
            codeToId(code: $code, node: "Organization") {
              id
            }
          }
        `,
        variables: { code },
      })
      .pipe(map((result) => _.get(result, 'data.codeToId.id')));
  }
}
