import { formatNumber } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'distance',
})
export class DistancePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: number, unit: 'm' | 'ft' | 'lat' | 'long' = 'ft', scale = 1): string {
    if (!_.isFinite(value)) {
      return '';
    }
    value = value / scale;
    const locale = this.locale;
    const inches = value * 12;
    switch (unit) {
      case 'ft':
        if (inches < 12) {
          return `${formatNumber(inches, locale, '1.0-1')}"`;
        }
        return (
          `${formatNumber(Math.floor(inches / 12), locale, '1.0-0')}'` +
          ` ${formatNumber(inches % 12, locale, '1.0-1')}"`
        );
      case 'm':
        return formatNumber(value * 0.3048, this.locale, '1.0-3');
      case 'lat':
        return this.latLong(value, ['N', 'S']);
      case 'long':
        return this.latLong(value, ['E', 'W']);
    }
  }

  private latLong(dec: number, direction: string[]): string {
    const dir = direction[dec < 0 ? 1 : 0];
    dec = Math.abs(dec);
    const d = Math.floor(dec);
    const m = Math.floor(60 * (dec - d));
    const s = 3600 * (dec - d) - 60 * m;

    return `${d}° ${m}' ${formatNumber(s, this.locale, '1.0-3')}" ${dir}`;
  }
}
