<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onTouched()" />
  <button matSuffix mat-icon-button *ngIf="control.value" (click)="clear()" matTooltip="Clear" i18n-matTooltip>
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
  <mat-option *ngFor="let org of filteredOrganizations | async" [value]="org">
    <span class="code">{{ org.id }}</span>
    {{ org.name }}
  </mat-option>
</mat-autocomplete>
