import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SESSION, Session } from '../models';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    @Inject(SESSION) private session: Session,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.session.isLoggedIn) {
      return true;
    }

    // When the page refreshes, session will not have been initialized
    // Try getting the information right here
    return this.authenticationService.checkLogin().pipe(
      catchError((error) => {
        return of(undefined);
      }),
      map(() => {
        if (this.session.isLoggedIn) {
          return true;
        } else {
          this.authenticationService.afterLoginUrl = state.url;
          this.router.navigate(['/welcome']);
          return false;
        }
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
