<div #mapContainer class="map-container" [ngClass]="{ 'map-download-container': (this.renderingMapImage$ | async) }">
  <google-map height="100%" width="100%" (idle)="idle()"></google-map>
  <button
    *ngIf="(this.renderingMapImage$ | async) === false && this.showDownload === true"
    mat-mini-fab
    color="primary"
    i18n-aria-label
    aria-label="Download Map"
    class="map-download-btn"
    (click)="downloadMap()">
    <mat-icon>download</mat-icon>
  </button>
</div>

<div #strings class="strings">
  <span id="facing" i18n>Facing &lt;%- facing %&gt;, on &lt;%- read %&gt; side of road</span>
  <span id="impressions" i18n>Daily impressions: &lt;%- impressionsData.targetImpressions %&gt;</span>
</div>
