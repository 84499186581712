export interface POI {
  latitude: number;
  longitude: number;
  distance?: number;
  name?: string;
}

export interface ZIP {
  postalCode: string;
  name?: string;
}

export interface StoredQuery {
  id: string;
  name: string;
  updated: string;
  distance: number;
  pointsOfInterest: POI[];
  postalCodes: ZIP[];
  enabled: string[];
  units: {
    id: string;
    name: string;
    latitude: number;
    longitude: number;
  }[];
  count: number;
}
