import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as _ from 'lodash';

@Component({
  selector: 'adkom-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(MatSidenav, { static: true }) public navigation: MatSidenav;

  constructor(private router: Router, private route: ActivatedRoute, private gtmService: GoogleTagManagerService) {}

  ngOnInit() {
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationError) {
        this.router.navigate(['/error'], {
          queryParams: {
            redirect: event.url,
            error: _.get(event, 'error.message', ''),
          },
        });
      } else if (event instanceof NavigationStart && this.navigation.opened) {
        this.navigation.close();
      } else if (event instanceof NavigationEnd) {
        this.logPageview();
      }
    });
  }

  public toggleNavigation() {
    this.navigation.toggle();
  }

  private logPageview(): void {
    const segments: string[] = [];
    let pageTitle = '';
    let route = this.route.snapshot.firstChild;
    while (route) {
      segments.push(route.routeConfig.path);
      pageTitle = _.get(route.component, 'name', '');
      route = route.firstChild;
    }

    this.gtmService.pushTag({
      event: 'Pageview',
      pagePath: segments.join('/'),
      pageTitle,
    });
  }
}
