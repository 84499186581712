<form novalidate [formGroup]="form">
  <mat-form-field>
    <mat-label i18n>Email</mat-label>
    <input matInput formControlName="email" />
  </mat-form-field>
  <mat-form-field>
    <mat-label i18n>Password</mat-label>
    <input matInput formControlName="password" type="password" />
  </mat-form-field>
  <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid" (click)="login()" i18n>Login</button>
</form>

<div #strings class="strings">
  <span id="loginError" i18n>Invalid email or password</span>
</div>
