import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import * as MarkerClusterer from '@googlemaps/markerclustererplus';
import * as _ from 'lodash';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { delay, first, switchMap } from 'rxjs/operators';
import { MediaUnit, POI } from '../../models';
import { FacingPipe, RoadSidePipe } from '../../pipes';
import { ExportService, SignService, TranslateService } from '../../services';

const METERS_PER_MILE = 1609;
const DEGREES_PER_MILE = 0.0145;

@Component({
  selector: 'core-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild(GoogleMap, { static: true }) map: GoogleMap;
  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;
  @ViewChild('strings', { static: true, read: ElementRef }) s: ElementRef;

  @Input() signs: MediaUnit[] = [];
  @Input() pois: POI[] = [];
  @Input() showRemove = false;
  @Input() showDownload = false;
  @Output() removeSigns = new EventEmitter<MediaUnit[]>();

  public options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
  };
  public renderingMapImage$: Subject<boolean> = new BehaviorSubject(false);
  private hasBeenIdle = false;

  private circles: google.maps.Circle[] = [];
  private markers: google.maps.Marker[] = [];
  private cluster: any;
  private infoWindow: google.maps.InfoWindow;

  private select?: {
    start?: google.maps.LatLng;
    boundingBox?: google.maps.Rectangle;
  };

  private infoTemplate: _.TemplateExecutor;

  private signPin = {
    path: 'M0 0 v-10 h-10 v-10 h30 v10 h-20z',
    fillColor: 'white',
    fillOpacity: 0.8,
    scale: 1,
    strokeColor: 'red',
    strokeWeight: 2,
  };

  constructor(
    private signService: SignService,
    private exportService: ExportService,
    @Inject('html2canvas')
    private h2c: (element: HTMLElement, options?: any) => Promise<HTMLCanvasElement>,
    public elementRef: ElementRef,
    private facingPipe: FacingPipe,
    private roadSidePipe: RoadSidePipe,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    const map = this.mapContainer.nativeElement.firstChild;
    map.addEventListener('mousedown', (event) => this.startSelect(event));
    map.addEventListener('mouseup', (event) => this.endSelect(event));
    map.addEventListener('mouseleave', (event) => this.cancelSelect(event));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pois) {
      this.updatePOIs();
    }
    if (changes.signs) {
      this.updateSigns();
    }
    if (this.hasBeenIdle) {
      this.defaultMap();
    }
  }

  idle() {
    if (!this.hasBeenIdle) {
      this.defaultMap();
    }
    this.hasBeenIdle = true;
  }

  private defaultMap() {
    // Set the map for all circle
    const map = this.map.googleMap;
    _.invoke(map, 'setOptions', this.options);
    _.forEach(this.circles, (circle) => circle.setMap(map));
    _.forEach(this.markers, (marker) => marker.setMap(map));
    if (this.pois.length) {
      const south = _.min(_.map(this.pois, (poi) => poi.latitude - poi.distance * DEGREES_PER_MILE));
      const north = _.max(_.map(this.pois, (poi) => poi.latitude + poi.distance * DEGREES_PER_MILE));
      const west = _.min(_.map(this.pois, (poi) => poi.longitude - poi.distance * DEGREES_PER_MILE));
      const east = _.max(_.map(this.pois, (poi) => poi.longitude + poi.distance * DEGREES_PER_MILE));
      this.map.fitBounds({ east, north, south, west });
    } else if (this.signs.length === 1) {
      this.map.zoom = 14;
      _.invoke(map, 'setCenter', {
        lat: this.signs[0].latitude,
        lng: this.signs[0].longitude,
      });
    } else if (this.signs.length) {
      const padding = DEGREES_PER_MILE;
      const south = _.minBy(this.signs, 'latitude').latitude - padding;
      const north = _.maxBy(this.signs, 'latitude').latitude + padding;
      const west = _.minBy(this.signs, 'longitude').longitude - padding;
      const east = _.maxBy(this.signs, 'longitude').longitude + padding;
      this.map.fitBounds({ east, north, south, west });
    } else {
      this.map.zoom = 4;
      _.invoke(map, 'setCenter', { lat: 37.1, lng: -95.7 });
    }
  }

  private updatePOIs() {
    _.forEach(this.circles, (circle) => circle.setMap(null));
    this.circles = _.map(
      this.pois,
      (poi) =>
        new google.maps.Circle({
          strokeColor: '#FF0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#FF0000',
          fillOpacity: 0.05,
          map: this.map.googleMap,
          center: { lat: poi.latitude, lng: poi.longitude },
          radius: poi.distance * METERS_PER_MILE,
        })
    );
  }

  private updateSigns() {
    _.invoke(this.cluster, 'setMap', null);
    _.forEach(this.markers, (marker) => marker.setMap(null));
    const existing: { latitude: number; longitude: number }[] = [];
    this.markers = _.map(this.signs, (sign) => {
      const marker = new google.maps.Marker({
        title: sign.name,
        // label: 'Label', // Shows always
        position: this.getPosition(sign, existing),
        map: this.map.googleMap,
        // icon: this.signPin,
        icon: '/assets/adkom-static-icon-black.png',
      });
      marker.addListener('click', () => this.showDetails(marker, sign));
      return marker;
    });
    this.cluster = new MarkerClusterer.default(this.map.googleMap, this.markers, {
      maxZoom: 10,
      styles: [
        {
          textColor: 'white',
          width: 35,
          height: 44,
          url: '/assets/clusters/AdkomGrouping1.png',
          anchorIcon: [40, 14],
          anchorText: [2, 10],
          fontWeight: 'bold',
        },
        {
          textColor: 'white',
          width: 44,
          height: 44,
          url: '/assets/clusters/AdkomGrouping2.png',
          anchorIcon: [40, 14],
          anchorText: [2, 10],
          fontWeight: 'bold',
        },
        {
          textColor: 'white',
          width: 52,
          height: 44,
          url: '/assets/clusters/AdkomGrouping3.png',
          anchorIcon: [40, 14],
          anchorText: [2, 10],
          fontWeight: 'bold',
        },
      ],
    });
  }

  // Offset matching signs so you can select on map
  private getPosition(
    pos: { latitude: number; longitude: number },
    existing: { latitude: number; longitude: number }[]
  ): { lat: number; lng: number } {
    const smallDistance = 0.00003;
    const latitude = _.round(pos.latitude, 5);
    const longitude = _.round(pos.longitude, 5);
    const found = _.sumBy(existing, (test) => (test.latitude === latitude && test.longitude === longitude ? 1 : 0));
    existing.push({ latitude, longitude });
    return {
      lat: pos.latitude + smallDistance * found,
      lng: pos.longitude + smallDistance * found,
    };
  }

  private showDetails(marker: google.maps.Marker, sign: MediaUnit) {
    this.signService
      .get(
        [
          'id',
          'name',
          'photo { thumbnailUrl }',
          'city',
          'province',
          'location',
          'description',
          'read',
          'facing',
          'impressionsData { targetImpressions }',
        ],
        sign.id
      )
      .subscribe((details) => {
        if (!this.infoWindow) {
          this.infoWindow = new google.maps.InfoWindow();
          this.infoTemplate = this.getInfoTemplate();
        }
        if (this.showRemove) {
          google.maps.event.addListenerOnce(this.infoWindow, 'domready', () => {
            const removeButton = document.getElementById('remove-sign');
            removeButton.addEventListener('click', () => this.removeSigns.next([sign]));
          });
        }
        this.infoWindow.setContent(
          this.infoTemplate(
            _.defaults(
              {
                read: this.roadSidePipe.transform(details.read as any),
                facing: this.facingPipe.transform(details.facing as any),
              },
              details,
              {
                description: '',
                showRemove: this.showRemove,
              }
            )
          )
        );
        this.infoWindow.open(this.map.googleMap, marker);
      });
  }

  private toLatlng(event: MouseEvent): google.maps.LatLng {
    const map = this.map.googleMap;
    const ne = map.getBounds().getNorthEast();
    const sw = map.getBounds().getSouthWest();
    const projection = map.getProjection();
    const topRight = projection.fromLatLngToPoint(ne);
    const bottomLeft = projection.fromLatLngToPoint(sw);
    const scale = 1 << map.getZoom();
    return projection.fromPointToLatLng(
      new google.maps.Point(event.offsetX / scale + bottomLeft.x, event.offsetY / scale + topRight.y)
    );
  }

  private startSelect(event: MouseEvent): void {
    if (this.showRemove && event.shiftKey) {
      const map = this.map.googleMap;
      const start = this.toLatlng(event);
      this.select = {
        start,
        boundingBox: new google.maps.Rectangle({
          map,
          bounds: new google.maps.LatLngBounds(start),
          fillOpacity: 0.15,
          strokeColor: 'red',
          strokeWeight: 0.9,
          clickable: false,
        }),
      };
      this.map.googleMap.setOptions({
        gestureHandling: 'none',
      });
    }
  }

  @HostListener('mousemove', ['$event'])
  public updateSelect(event: MouseEvent): void {
    if (this.select) {
      const newBounds = new google.maps.LatLngBounds(this.select.start);
      newBounds.extend(this.toLatlng(event));
      this.select.boundingBox.setBounds(newBounds);
    }
  }

  private endSelect(event: MouseEvent) {
    if (this.select) {
      const bounds = this.select.boundingBox.getBounds();
      const toDelete = _.filter(this.signs, (sign) => bounds.contains({ lat: sign.latitude, lng: sign.longitude }));
      if (!_.isEmpty(toDelete)) {
        this.removeSigns.next(toDelete);
      }
      this.cancelSelect(event);
    }
  }

  private cancelSelect(event: MouseEvent) {
    if (this.select) {
      this.map.googleMap.setOptions({
        gestureHandling: 'auto',
      });
      this.select.boundingBox.setMap(null);
      this.select = undefined;
    }
  }

  public downloadMap() {
    this.renderingMapImage$.next(true);
    this.map.googleMap.setOptions({
      fullscreenControl: false,
      zoomControl: false,
    });
    of({})
      .pipe(
        delay(2000),
        switchMap(() => from(this.h2c(this.mapContainer.nativeElement, { useCORS: true }))),
        first()
      )
      .subscribe((canvas) => {
        canvas.toBlob((blob) => this.exportService.export('proposal-map.png', blob, 'image/png'));
        this.renderingMapImage$.next(false);
        this.map.googleMap.setOptions({
          fullscreenControl: true,
          zoomControl: true,
        });
      });
  }

  private getInfoTemplate(): _.TemplateExecutor {
    // Delay generating this until needed, as we need to wait for the component to render
    return _.template(`<div class="map-info-window">
    <% if (photo) { %>
      <div class="image">
        <img src="<%= photo.thumbnailUrl %>">
      </div>
    <% } %>
      <div class="details">
        <h3><%- name %></h3>
        <p><%- city %>, <%- province %></p>
        <p><%- location %></p>
        <p><%- description %></p>
        <p>${this.translate.s('facing', this.s)}</p>
        <p>${this.translate.s('impressions', this.s)}</p>
        <% if (showRemove) { %>
        <p style="position: absolute;bottom: -8px;right: 0px;">
          <button id="remove-sign" class="mat-icon-button mat-button-base mat-warn">
            <span class="mat-icon material-icons">delete</mat-icon>
          </button>
        </p>
        <% } %>
      </div>
    </div>`);
  }
}
