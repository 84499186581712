<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input type="text" matInput [formControl]="control" [matAutocomplete]="auto" (blur)="onTouched()" />
  <button matSuffix mat-icon-button *ngIf="!control.value" (click)="chooseMe()" matTooltip="Me" i18n-matTooltip>
    <mat-icon>face</mat-icon>
  </button>
  <button matSuffix mat-icon-button *ngIf="control.value" (click)="clear()" matTooltip="Clear" i18n-matTooltip>
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
  <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
    <span class="code">{{ user.id }}</span>
    {{ display(user) }}
  </mat-option>
</mat-autocomplete>
