import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from './api.service';

interface Point {
  x: number;
  y: number;
}

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(private api: ApiService, private http: HttpClient) {}

  public generate(
    baseUrl: string | null,
    adUrl: string | null,
    width: number,
    height: number,
    corners: any,
    fillColor: string
  ): Observable<Blob> {
    // eslint-disable-next-line max-len
    // corners = {'topLeft':{'x':669,'y':197},'topRight':{'x':777,'y':193},'bottomLeft':{'x':669,'y':225},'bottomRight':{'x':777,'y':221},'status':'OK'};
    const merge = !!(adUrl && width && height && !_.isEmpty(corners));
    if (!baseUrl) {
      return EMPTY;
    }

    return forkJoin([this.getImageFromUrl(baseUrl), merge ? this.getImageFromUrl(adUrl) : of(null)]).pipe(
      concatMap(([baseImage, adImage]) =>
        forkJoin([of(baseImage), this.transform(baseImage, adImage, width / height, corners, fillColor)])
      ),
      concatMap(([baseImage, adImage]) => {
        // Return as size of original
        const elem = document.createElement('canvas');
        elem.width = baseImage.width;
        elem.height = baseImage.height;
        const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
        ctx.drawImage(baseImage, 0, 0);
        if (adImage) {
          ctx.drawImage(adImage, 0, 0);
        }
        return new Observable<Blob>((subscriber) => {
          ctx.canvas.toBlob((blob) => {
            subscriber.next(blob);
            subscriber.complete();
          }, 'image/jpeg');
        });
      })
    );
  }

  public getImageFromUrl(url: string): Observable<HTMLImageElement> {
    return new Observable((subscriber) => {
      // Chrome browser cache does breaks CORS if previously used as image on the page
      // istanbul ignore if
      if (url.startsWith('http')) {
        url += '?x-cors=1';
      }
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        subscriber.next(img);
        subscriber.complete();
      };
      img.onerror = (err, a, b) => {
        subscriber.error('Unable to load image');
      };
      img.src = url;
    });
  }

  public uploadImage(filename: string, orgId: string, file: Blob): Observable<string> {
    return this.api.request('POST', `/api/account/organization/${orgId}/files/${this.safeName(filename)}`, '').pipe(
      switchMap((result) => {
        return this.http
          .post(
            result.url,
            this.toFormData({
              ...result.data,
              'Content-Type': file.type,
              file,
            })
          )
          .pipe(map(() => <any>result.data['x-amz-meta-file-link']));
      })
    );
  }

  private safeName(name: string): string {
    // eslint-disable-next-line no-useless-escape
    return name.replace(/[^a-z0-9\.]/gi, '_');
  }

  private toFormData(values: any): FormData {
    const formData = new FormData();
    _.forOwn(values, (value, key) => formData.append(key, value));
    return formData;
  }

  private transform(
    baseImage: HTMLImageElement,
    adImage: HTMLImageElement | null,
    boardRatio: number,
    corners: any,
    fillColor: string
  ): Observable<HTMLImageElement | null> {
    if (!adImage || !corners) {
      return of(null);
    }
    const imageRatio = adImage.width / adImage.height;

    // Center inside of white ad with the aspect ratio of the sign
    let adWidth = imageRatio > boardRatio ? adImage.width : (adImage.width * boardRatio) / imageRatio;
    let adHeight = imageRatio > boardRatio ? (adImage.height * imageRatio) / boardRatio : adImage.height;

    // SVG tries to optimize, and will cut of the part of ad image that is larger than the image
    // before it does the transformation, lets scale the image here
    const scale = _.max([1, adWidth / baseImage.width, adHeight / baseImage.height]);
    adWidth /= scale;
    adHeight /= scale;

    const elem = document.createElement('canvas');
    elem.width = adWidth;
    elem.height = adHeight;
    const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
    ctx.fillStyle = fillColor;
    ctx.fillRect(0, 0, adWidth, adHeight);
    ctx.drawImage(
      adImage,
      (adWidth - adImage.width / scale) / 2,
      (adHeight - adImage.height / scale) / 2,
      adImage.width / scale,
      adImage.height / scale
    );

    const matrix = this.getTransformMatrix(
      adWidth,
      adHeight,
      corners.topLeft,
      corners.topRight,
      corners.bottomRight,
      corners.bottomLeft
    );

    // Apply transformation
    // Canvas doesn't allow 3D transformations, so we do it in an SVG and render that on top of the canvas
    const xlinkNS = 'http://www.w3.org/1999/xlink',
      svgNS = 'http://www.w3.org/2000/svg';
    const svg = document.createElementNS(svgNS, 'svg'),
      defs = document.createElementNS(svgNS, 'defs'),
      style = document.createElementNS(svgNS, 'style'),
      image = document.createElementNS(svgNS, 'image');
    image.setAttributeNS(xlinkNS, 'href', ctx.canvas.toDataURL());
    image.setAttribute('width', adWidth.toString());
    image.setAttribute('height', adHeight.toString());
    style.innerHTML = `svg{transform-origin: 0 0; transform:matrix3d(${matrix.join(',')});}`;
    svg.appendChild(defs);
    defs.appendChild(style);
    svg.appendChild(image);
    svg.setAttribute('width', baseImage.width.toString());
    svg.setAttribute('height', baseImage.height.toString());
    const svgStr = new XMLSerializer().serializeToString(svg);
    const url = URL.createObjectURL(new Blob([svgStr], { type: 'image/svg+xml' }));
    return this.getImageFromUrl(url).pipe(tap(() => URL.revokeObjectURL(url)));
  }

  public getTransformMatrix(
    width: number,
    height: number,
    topLeft: Point,
    topRight: Point,
    bottomRight: Point,
    bottomLeft: Point
  ): number[] {
    const x = this.getPerspectiveTransform(
      [
        { x: 0, y: 0 },
        { x: width, y: 0 },
        { x: width, y: height },
        { x: 0, y: height },
      ],
      [
        topLeft,
        { x: topRight.x + 1, y: topRight.y },
        { x: bottomRight.x + 1, y: bottomRight.y + 1 },
        { x: bottomLeft.x, y: bottomLeft.y + 1 },
      ]
    );

    // matrix3d is homogeneous coords in column major
    const M = [x[0], x[3], 0, x[6], x[1], x[4], 0, x[7], 0, 0, 1, 0, x[2], x[5], 0, 1];
    return M;
  }
  /* eslint-disable max-len */
  /**
   * Calculates coefficients of perspective transformation
   * @param xy Four orignal points
   * @param uv Four transformed points
   * @description
   * From OpenCV:
   * https://github.com/opencv/opencv/blob/11b020b9f9e111bddd40bffe3b1759aa02d966f0/modules/imgproc/src/imgwarp.cpp#L3001
   * which maps (xi,yi) to (ui,vi), (i=1,2,3,4):
   *
   *      c00*xi + c01*yi + c02
   * ui = ---------------------
   *      c20*xi + c21*yi + c22
   *
   *      c10*xi + c11*yi + c12
   * vi = ---------------------
   *      c20*xi + c21*yi + c22
   *
   * Coefficients are calculated by solving linear system:
   * / x0 y0  1  0  0  0 -x0*u0 -y0*u0 \ /c00\ /u0\
   * | x1 y1  1  0  0  0 -x1*u1 -y1*u1 | |c01| |u1|
   * | x2 y2  1  0  0  0 -x2*u2 -y2*u2 | |c02| |u2|
   * | x3 y3  1  0  0  0 -x3*u3 -y3*u3 |.|c10|=|u3|,
   * |  0  0  0 x0 y0  1 -x0*v0 -y0*v0 | |c11| |v0|
   * |  0  0  0 x1 y1  1 -x1*v1 -y1*v1 | |c12| |v1|
   * |  0  0  0 x2 y2  1 -x2*v2 -y2*v2 | |c20| |v2|
   * \  0  0  0 x3 y3  1 -x3*v3 -y3*v3 / \c21/ \v3/
   *
   * where:
   *   cij - matrix coefficients, c22 = 1
   */

  private getPerspectiveTransform(src: Point[], dest: Point[]): number[] {
    // Ax = b
    const A = _.times(8, () => _.times(8, _.constant(0)));
    const b = _.times(8, _.constant(0));

    _.times(4, (i) => {
      A[i][0] = A[i + 4][3] = src[i].x;
      A[i][1] = A[i + 4][4] = src[i].y;
      A[i][2] = A[i + 4][5] = 1;
      A[i][3] = A[i][4] = A[i][5] = A[i + 4][0] = A[i + 4][1] = A[i + 4][2] = 0;
      A[i][6] = -src[i].x * dest[i].x;
      A[i][7] = -src[i].y * dest[i].x;
      A[i + 4][6] = -src[i].x * dest[i].y;
      A[i + 4][7] = -src[i].y * dest[i].y;
      b[i] = dest[i].x;
      b[i + 4] = dest[i].y;
    });

    return this.solve(A, b);
  }

  // Solve Linear equation Ax=b
  // returns x
  private solve(A: number[][], b: number[]): number[] {
    const n = A.length;
    const A_aug = _.cloneDeep(A);
    _.times(n, (i) => A_aug[i].push(b[i]));

    _.times(n, (i) => {
      // Search for maximum in this column
      let max_value = 0;
      let max_row = null;

      for (let k = i; k < n; k++) {
        const current_max_value = Math.abs(A_aug[k][i]);

        if (current_max_value > max_value) {
          max_value = current_max_value;
          max_row = k;
        }
      }

      // Swap maximum row with current row (column by column)
      for (let k = i; k < n + 1; k++) {
        const tmp = A_aug[i][k];
        A_aug[i][k] = A_aug[max_row][k];
        A_aug[max_row][k] = tmp;
      }

      // Make all rows below this one 0 in current column
      for (let k = i + 1; k < n; k++) {
        const c = -A_aug[k][i] / A_aug[i][i];
        for (let j = i; j < n + 1; j++) {
          if (i === j) A_aug[k][j] = 0;
          else A_aug[k][j] += c * A_aug[i][j];
        }
      }
    });

    // Solve equation Ax=b
    const x = new Array(n);
    for (let i = n - 1; i > -1; i--) {
      x[i] = A_aug[i][n] / A_aug[i][i];
      for (let k = i - 1; k > -1; k--) A_aug[k][n] -= A_aug[k][i] * x[i];
    }
    return x;
  }
}
