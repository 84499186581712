import { CoreModule, Environment, MaterialModule, PAGETITLE, SESSION, VERSION } from '@adkom/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import html2canvas from 'html2canvas';
import { GoogleApiModule, NgGapiClientConfig, NG_GAPI_CONFIG } from 'ng-gapi';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { AppRouteReuseStrategy, AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NavigationErrorComponent } from './navigation-error/navigation-error.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NoRightsComponent } from './no-rights/no-rights.component';
import version from './version.json';
import { WelcomeComponent } from './welcome/welcome.component';

const gapiClientConfig: NgGapiClientConfig = {
  client_id: environment.GAPI_CLIENT_ID,
  discoveryDocs: [],
};

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    NavigationComponent,
    NoRightsComponent,
    NavigationErrorComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    MaterialModule,
    ReactiveFormsModule,
    GraphQLModule,
    GoogleMapsModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
  ],
  providers: [
    { provide: Environment, useValue: environment },
    { provide: SESSION, useValue: { isLoggedIn: false } },
    { provide: PAGETITLE, useValue: new BehaviorSubject<string>('?') },
    { provide: 'googleTagManagerId', useValue: environment.TAG_MANAGER_ID },
    { provide: VERSION, useValue: version },
    { provide: 'html2canvas', useValue: html2canvas },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
