import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import * as _ from 'lodash';
import { CellParser } from '../classes/cell-parser';
import { PointOfInterest, ProposalColumn, Sign } from '../models';
import { formatFeet, formatInches, formatLat, formatLon } from './distance';
import { TranslateService } from './translate.service';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor(@Inject(LOCALE_ID) private locale: string, private translate: TranslateService) {}

  export(filename: string, data: string | Blob, type = 'text/csv;charset=utf-8;') {
    const blob = typeof data === 'string' ? new Blob(['\ufeff', data], { type }) : data;
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    //   const isSafariBrowser = navigator.userAgent.indexOf(
    //     'Safari') != -1 & amp; & amp;
    // navigator.userAgent.indexOf('Chrome') == -1;
    // /if Safari open in new window to save file with random filename. ;
    // if (isSafariBrowser) {
    //     / ;
    //     link.setAttribute('target', '_blank');
    // }

    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  public exportData(column: ProposalColumn, sign: any): string {
    const key = _.trim(_.first(_.split(column.column, '{')));
    switch (key) {
      case '!blank':
        return '';
      case '!template':
        return this.applyTemplate(column.data, sign);
      case '!internal':
        return _.get(sign, `internal_${column.data}`, '');
      case '!impressionsData': {
        const val = _.get(_.get(sign, column.data.split('.')[0]), column.data.split('.')[1]);
        return Array.isArray(val) ? '[' + val.join(', ') + ']' : val;
      }
      case 'pointsOfInterest':
        return this.formatPOI(_.get(sign, key), this.parseCategories(column.data));
      default:
        return this.applyTemplate(column.data, {
          value: _.get(sign, key),
        });
    }
  }

  public defaultFormat(sign: Sign, columnName: string) {
    if (/:/.test(columnName)) {
      return _.get(sign, _.first(_.split(columnName, ':')), '');
    }
    const key = _.trim(_.first(_.split(columnName, '{')));
    switch (key) {
      case 'pointsOfInterest':
        return this.formatPOI(_.get(sign, key));
      case 'exclusion':
      case 'alternateFlipDurations':
        return _.join(_.get(sign, key, []), '|');
      case 'isIlluminated':
      case 'embellishmentAllow':
      case 'extensionAllow':
        return this.translate.s(_.get(sign, key, false) ? 'Yes' : 'No', 'column');
      default:
        return _.get(sign, this.getPath(columnName), '');
    }
  }

  private getPath(columnName: string): string {
    return columnName.replace(/ *\{ */g, '.').replace(/ *\} */g, '');
  }

  private parseCategories(categories: string): Array<string> {
    return _.split(categories, ',').map((category) => _.trim(category));
  }

  private formatPOI(pointsOfInterest: Array<PointOfInterest>, categories?: Array<string>): string {
    const populatedCategories = _.groupBy(_.reject(pointsOfInterest, _.isNil), 'category');
    const filteredCategories = categories
      ? _.pickBy(populatedCategories, (_val, key) => categories.includes(key))
      : populatedCategories;

    return _.map(filteredCategories, (val, key) => {
      const categoryType = this.translate.s(_.camelCase(key), 'poi');
      return `${categoryType}: ${_.map(val, 'name').join(', ')}`;
    }).join('. ');
  }

  private applyTemplate(template: string, data: any): string {
    if (_.startsWith(template, '=')) {
      return new CellParser(template, data).evaluate(this.locale);
    }
    let formatted = _.replace(template, /{{([a-z_0-9[\].]+)}}/gi, (substring, key) => _.get(data, key) || '');
    formatted = formatted.replace(
      // eslint-disable-next-line no-useless-escape
      /(round)\(([0-9\.\-*+/]*),(\d+)\)/g,
      (match, format, value, precision) => {
        if (!value) {
          return '';
        }
        // eslint-disable-next-line no-eval
        return eval(value).toFixed(precision);
      }
    );
    formatted = formatted.replace(
      // eslint-disable-next-line no-useless-escape
      /(feet|inches|lat|lon)\((-?[0-9\.]*)\)/g,
      (match, format, value) => {
        if (!value) {
          return '';
        }
        switch (format) {
          case 'feet':
            return formatFeet(this.locale, parseFloat(value));
          case 'inches':
            return formatInches(this.locale, parseFloat(value));
          case 'lat':
            return formatLat(this.locale, parseFloat(value));
          case 'lon':
            return formatLon(this.locale, parseFloat(value));
        }
      }
    );
    formatted = formatted.replace(/(shortId)\(([0-9a-z-]*)\)/g, (match, format, value) => {
      if (!value) {
        return '';
      }
      switch (format) {
        case 'shortId':
          return value.substring(0, 8);
      }
    });
    return formatted;
  }

  public getColumns(wanted: ProposalColumn[], always?: Array<string>): string[] {
    const columns: any = {};

    _.forEach(always, (field) => this.add(field, columns));

    _.forEach(wanted, (column) => {
      if (column.column === '!blank') {
        // Nothing needed
      } else if (column.column === '!template') {
        if (_.startsWith(column.data, '=')) {
          _.forEach(new CellParser(column.data).extractFields(), (field) => this.add(field, columns));
        } else {
          _.replace(
            column.data,
            // eslint-disable-next-line no-useless-escape
            /{{([a-z_0-9[\]\.]+)}}/gi,
            (substring, key) => {
              this.add(key, columns);
              return '';
            }
          );
        }
      } else if (column.column === '!internal') {
        this.add(`internal_${column.data}`, columns);
      } else if (column.column === '!impressionsData') {
        if (column.impressionsDataField && !column.data) {
          const seg = column.targetSegment?.id ? column.targetSegment.id : 7166;
          const days = column.periodDays ? column.periodDays : 1;
          const derivedSpotData = column.derivedSpotData ? 1 : 0;
          column.data = `geopath_${seg}_${days}_${derivedSpotData}.${column.impressionsDataField}`;
        }
        if (column.data) {
          this.add(column.data, columns);
        }
      } else {
        this.add(column.column, columns);
      }
    });
    return _.map(columns, (value, key) =>
      typeof value === 'string' ? value : `${key} { ${Array.from(value).join(' ')} }`
    );
  }

  private add(key: string, existing: any) {
    const parts = key.replace(/\[[0-9]+\]/gi, '').split('.');
    if (parts.length === 1) {
      existing[parts[0]] = parts[0];
    } else if (existing[parts[0]]) {
      existing[parts[0]].add(parts[1]);
    } else {
      existing[parts[0]] = new Set<string>([parts[1]]);
    }
  }
}
