import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SESSION, Session } from '../models';
import { ApiService } from './api.service';
import { RightsService } from './rights.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private api: ApiService, private rights: RightsService, @Inject(SESSION) private session: Session) {}

  /** The url to navigate to after login */
  public afterLoginUrl = '/home';

  checkLogin(): Observable<Session> {
    this.session.isLoggedIn = false;
    return this.api.get('/api/account/login/').pipe(
      switchMap((results) => this.rights.initialize(results)),
      map((results) => this.initSession(results))
    );
  }

  login(email: string, password: string): Observable<Session> {
    this.session.isLoggedIn = false;
    return this.api.post('/api/account/login/', this.api.toFormData({ email, password })).pipe(
      switchMap((results) => this.rights.initialize(results)),
      map((results) => this.initSession(results))
    );
  }

  initSession(data: Partial<Session>) {
    data.isLoggedIn = true;

    if (/^\d{10}$/.test(data.user?.phone)) {
      const phone = data.user.phone;
      data.user.phoneFormated = `(${phone.substr(0, 3)}) ${phone.substr(3, 3)}-${phone.substr(6, 4)}`;
    }

    return _.assign(this.session, data);
  }

  logout(): Observable<boolean> {
    this.session.isLoggedIn = false;
    this.rights.clear();
    return this.api.get('/auth/logout/').pipe(
      catchError(() => of(true)),
      map(() => true)
    );
  }
}
