import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Organization, OrgType, User } from '../models';
import { ApiService } from './api.service';

interface LoginResponse {
  user: User;
  organizations: Array<Organization>;
}

@Injectable({
  providedIn: 'root',
})
export class RightsService {
  private user: User;
  private organizations: Organization[];
  private permissionsByOrg: { [key: string]: Array<string> };

  constructor(private api: ApiService) {}

  initialize(results: LoginResponse): Observable<LoginResponse> {
    this.user = results.user;
    this.organizations = results.organizations;
    if (!this.organizations.length) {
      return of(results);
    }
    return forkJoin(_.map(this.organizations, (org) => this.api.get(`/api/account/organization/${org.id}/info/`))).pipe(
      map((infos) => {
        this.permissionsByOrg = _.zipObject(_.map(this.organizations, 'id'), _.map(infos, 'permissions'));
        return results;
      })
    );
  }

  clear() {
    this.user = undefined;
    this.organizations = [];
    this.permissionsByOrg = {};
  }

  public hasAny(): boolean {
    return this.user?.is_superuser || _.some(this.organizations, { type: 'BLIP' });
  }

  // Return the first org with this type
  getOrgByType(type: OrgType): Organization | undefined {
    return _.find(this.organizations, { type });
  }

  // Return the org by this id, if any
  getOrgById(id: string): Organization | undefined {
    return _.find(this.organizations, { id });
  }

  byType(type: OrgType): boolean {
    return !!this.getOrgByType(type);
  }
}
