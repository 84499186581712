import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'techType',
})
export class TechTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.translate.s('techtype_' + value, 'pipe');
  }
}
