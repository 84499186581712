import { AppVersion, AuthenticationService, PAGETITLE, SESSION, Session, VERSION } from '@adkom/core';
import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppComponent } from '../app.component';

@Component({
  selector: 'adkom-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('about') aboutDlg: TemplateRef<any>;

  constructor(
    @Inject(SESSION) public session: Session,
    @Inject(PAGETITLE) public pageTitle: BehaviorSubject<string>,
    @Inject(VERSION) public version: AppVersion,
    @Optional() public app: AppComponent,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.pageTitle.subscribe((title) => (document.title = title));
  }

  public logout() {
    this.authenticationService.logout().subscribe(() => {
      this.router.navigateByUrl('/welcome');
    });
  }

  public openAbout() {
    this.dialog.open(this.aboutDlg);
  }
}
