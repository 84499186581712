import { ComponentFactoryResolver, ElementRef, Injectable, Injector, Type } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private registered: { [key: string]: { s: ElementRef } } = {};

  constructor(private resolver: ComponentFactoryResolver, private injector: Injector) {}

  /**
   * Register a component with the translate service
   * @param key The key to use when retriving strings
   * @param component The component that contains the strings
   */
  public register(key: string, comp: Type<{ s: ElementRef }>) {
    const component = this.resolver.resolveComponentFactory(comp).create(this.injector).instance;
    this.registered[key] = component;
  }

  /**
   * Translate string in file
   * @param id Id of the string to translate
   * @param element ElementRef of strings
   */
  public s(id: string, element: ElementRef | string): string {
    if (_.isString(element)) {
      element = this.registered[element].s;
    }
    const el = element.nativeElement.querySelector(`#${id}`);
    return el ? el.textContent : `Unknown: ${id}`;
  }
}
