import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { StoredQuery } from '../../models';

@Component({
  selector: 'core-choose-stored-query',
  templateUrl: './choose-stored-query.component.html',
  styleUrls: ['./choose-stored-query.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChooseStoredQueryComponent,
      multi: true,
    },
  ],
})
export class ChooseStoredQueryComponent implements OnInit {
  public filteredStoredQueries: Observable<StoredQuery[]>;
  public control = new FormControl('');
  public onTouched = _.noop;
  private onChange = _.noop;
  public last: StoredQuery | null;

  @Input() orgId: string;
  @Output() edit = new EventEmitter<StoredQuery>();
  @Output() add = new EventEmitter<any>();

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.filteredStoredQueries = this.control.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((path) => this.queryStoredQueries(path)),
      shareReplay(1)
    );

    this.control.valueChanges.subscribe((value) => {
      if (_.isObject(value)) {
        this.setValue(<StoredQuery>value);
      } else if (!!this.last && this.last.name !== value) {
        this.setValue(null);
      }
    });
  }

  private setValue(value: StoredQuery | null): void {
    const id = _.get(value, 'id');
    if (id !== _.get(this.last, 'id')) {
      this.last = value;
      this.onChange(id);
    }
  }

  public clear() {
    this.control.patchValue('');
  }

  public writeValue(id: string | null): void {
    if (id) {
      this.apollo
        .query({
          query: gql`
            query StoredQuery($id: ID!) {
              storedQuery(id: $id) {
                id
                name
              }
            }
          `,
          variables: { id },
        })
        .subscribe((query) => {
          this.control.setValue((this.last = _.get(query, 'data.storedQuery')));
        });
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public display(query?: StoredQuery) {
    return query?.name;
  }

  private queryStoredQueries(name: string | StoredQuery): Observable<StoredQuery[]> {
    if (_.isObject(name)) {
      return of([name]);
    }
    if (!name) {
      return of([]);
    }
    return this.apollo
      .query({
        query: gql`
          query StoredQueries($orgId: ID, $name: String) {
            allStoredQueries(orgId: $orgId, first: 10, name: $name, source: "user", orderBy: "name") {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        `,
        variables: { orgId: this.orgId, name },
      })
      .pipe(map((result) => _.map(_.get(result, 'data.allStoredQueries.edges'), 'node')));
  }
}
