import { AuthGuard, OrgPrefResolver, UserPrefResolver, UserResolver } from '@adkom/core';
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NavigationErrorComponent } from './navigation-error/navigation-error.component';
import { NoRightsComponent } from './no-rights/no-rights.component';
import { OrganizationResolver } from './organization.resolver';
import { WelcomeComponent } from './welcome/welcome.component';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return super.shouldReuseRoute(future, curr) && !future.data.donotReuseComponent;
  }
}

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'welcome', component: WelcomeComponent },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'noaccount', component: NoRightsComponent },
      { path: 'error', component: NavigationErrorComponent },
    ],
  },
  {
    path: ':orgId',
    canActivateChild: [AuthGuard],
    resolve: {
      org: OrganizationResolver,
      orgPreferences: OrgPrefResolver,
      user: UserResolver,
      userPreferences: UserPrefResolver,
    },
    children: [
      { path: 'agency', loadChildren: () => import('@adkom/agency').then((m) => m.AgencyModule) },
      { path: 'client', loadChildren: () => import('@adkom/client').then((m) => m.ClientModule) },
      { path: 'support', loadChildren: () => import('@adkom/support').then((m) => m.SupportModule) },
    ],
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
