import { AppVersion, Environment, VERSION } from '@adkom/core';
import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';

export function createApollo(httpLink: HttpLink, environment: Environment, version: AppVersion) {
  const uri = `${environment.API_URL}/gql`;
  const defaultOptions: DefaultOptions = {
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  };
  const versionString = `${version.major}.${version.minor}.${version.patch} (${version.build})`;

  const app = setContext((operation, context) => ({
    headers: {
      'X-App': 'adkom',
      'X-App-Version': versionString,
    },
  }));

  return {
    link: ApolloLink.from([app, httpLink.create({ uri, withCredentials: true })]),
    cache: new InMemoryCache(),
    defaultOptions,
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, Environment, VERSION],
    },
  ],
})
export class GraphQLModule {}
