export type OrgType =
  // Master org, responsible for self-promo ads and system campaign templates
  | 'BLIP'
  // Sign operator
  | 'OPERATOR'
  // Customer of a sign operator or agency
  | 'ADVERTISER'
  // Marketplace self-service customer
  | 'MARKETPLACE'
  // Advertising agency, coordinating multiple advertisers
  | 'AGENCY';

export interface Organization {
  id: string;
  newId: string;
  owner: string | null;
  name: string;
  type: OrgType;
  syncAvails?: boolean;
}
