import { AuthenticationService, ToasterService, TranslateService } from '@adkom/core';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'adkom-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('strings', { static: true, read: ElementRef }) s: ElementRef;

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  public login() {
    this.authenticationService.login(this.form.value.email, this.form.value.password).subscribe(
      (result) => {
        this.router.navigateByUrl('/home');
      },
      (error) => {
        this.toaster.error(this.translate.s('loginError', this.s), error);
      }
    );
  }
}
