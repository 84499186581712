import { RightsService, Session, SESSION } from '@adkom/core';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'adkom-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(@Inject(SESSION) public session: Session, private rights: RightsService, private router: Router) {}

  ngOnInit() {
    if (!this.rights.hasAny()) {
      this.router.navigate(['../noaccount']);
    } else if (this.rights.byType('BLIP')) {
      this.router.navigate(['../', this.rights.getOrgByType('BLIP').id, 'support']);
    }
  }
}
