import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'facing',
})
export class FacingPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(direction: 'N' | 'E' | 'S' | 'W' | 'NE' | 'NW' | 'SE' | 'SW', reverse = false): any {
    if (reverse) {
      direction = <any>{
        N: 'S',
        E: 'W',
        S: 'N',
        W: 'E',
        NE: 'SW',
        NW: 'SE',
        SE: 'NW',
        SW: 'NE',
      }[direction];
    }
    return this.translate.s('dir_' + direction, 'pipe');
  }
}
