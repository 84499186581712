import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppVersion, Environment, VERSION } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private versionString: string;
  constructor(private http: HttpClient, private environment: Environment, @Inject(VERSION) version: AppVersion) {
    this.versionString = `${version.major}.${version.minor}.${version.patch} (${version.build})`;
  }

  get(
    endpoint: string,
    params?: HttpParams | { [param: string]: string | string[] },
    options: { noRemap?: boolean } = {}
  ): Observable<any> {
    return this.request('GET', endpoint, { params }).pipe(
      map((response: any) => (options.noRemap ? response : response.result))
    );
  }

  post(endpoint: string, body: any): Observable<any> {
    return this.request('POST', endpoint, { body }).pipe(map((response: any) => response.result));
  }

  patch(endpoint: string, body: any): Observable<any> {
    return this.request('PATCH', endpoint, { body }).pipe(map((response: any) => response.result));
  }

  put(endpoint: string, body: any): Observable<any> {
    return this.request('PUT', endpoint, { body }).pipe(map((response: any) => response.result));
  }

  request(method: 'GET' | 'POST' | 'PATCH' | 'PUT', endpoint: string, extra: any): Observable<any> {
    return this.http.request(method, `${this.environment.API_URL}${endpoint}`, this.getOptions(extra));
  }

  toFormData(values: any): FormData {
    const formData = new FormData();
    _.forOwn(values, (value, key) => formData.append(key, value));
    return formData;
  }

  private getOptions(extra?: any) {
    const options = _.omitBy(
      _.extend(
        {
          withCredentials: true,
        },
        extra
      ),
      _.isUndefined
    );
    _.set(options, 'headers.X-App', 'adkom');
    _.set(options, 'headers.X-App-Version', this.versionString);
    return options;
  }
}
