import { FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { PageInfo } from '../models';

export class PaginatedFilter {
  public page = 0;
  public pageSize = 10;
  public totalCount = 0;
  public filterChange: Observable<{
    after?: string;
    before?: string;
    filter: any;
  }>;
  private forceQuery = new BehaviorSubject(true);
  private pageInfo: PageInfo;
  private pageLoad: { after?: string; before?: string };

  constructor(private form: FormGroup, manual = false) {
    this.pageLoad = {};
    this.pageInfo = { hasNextPage: false, hasPreviousPage: false };
    if (manual) {
      this.filterChange = this.forceQuery.pipe(
        map(() => ({
          after: this.pageLoad.after,
          before: this.pageLoad.before,
          filter: this.form.value,
        }))
      );
    } else {
      this.filterChange = combineLatest([
        this.forceQuery,
        this.form.valueChanges.pipe(
          debounceTime(300),
          tap(() => {
            this.page = 0;
            this.pageLoad = {};
          }),
          startWith(this.form.value),
          distinctUntilChanged()
        ),
      ]).pipe(
        map(([force, filter]) => {
          return {
            after: this.pageLoad.after,
            before: this.pageLoad.before,
            filter,
          };
        })
      );
    }
  }

  public execute() {
    this.page = 0;
    this.pageLoad = {};
    this.forceQuery.next(true);
  }

  public changePage(event: PageEvent) {
    if (event.pageIndex === 0) {
      this.pageLoad = {};
    } else if (event.pageIndex > event.previousPageIndex) {
      this.pageLoad = { after: this.pageInfo.endCursor };
    } else {
      this.pageLoad = { before: this.pageInfo.startCursor };
    }
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.forceQuery.next(true);
  }

  public setPageInfo(info: { pageInfo: PageInfo; totalCount: number }) {
    this.totalCount = info.totalCount;
    this.pageInfo = info.pageInfo;
  }
}
