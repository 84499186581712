<h2 mat-dialog-title>
  <span class="title">{{ data.message }}</span>
  <span class="spacer"></span>
  <button
    mat-icon-button
    matTooltip="Copy to clipboard"
    i18n-matTooltip
    [cdkCopyToClipboard]="formated"
    [cdkCopyToClipboardAttempts]="5">
    <mat-icon>content_copy</mat-icon>
  </button>
</h2>
<mat-dialog-content>
  <pre>{{ formated }}</pre>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial i18n>Close</button>
</mat-dialog-actions>
