import { Injectable, NgZone, TemplateRef, ViewContainerRef } from '@angular/core';
import { first } from 'rxjs/operators';

export type ContainerType = 'Navigation' | 'Toolbar';

@Injectable({
  providedIn: 'root',
})
export class ContainerService {
  private containerLists: { [type: string]: Array<ViewContainerRef> } = {};

  constructor(private zone: NgZone) {}

  /** Register a new target for toolbars */
  registerContainer(type: ContainerType, container: ViewContainerRef) {
    const list = this.getContainerList(type);
    list.unshift(container);
  }

  unregisterContainer(type: ContainerType) {
    this.getContainerList(type).shift();
  }

  setUI(type: ContainerType, template: TemplateRef<any>) {
    // Timing issues on startup between register and setUI.
    // Delay this until all is stable
    this.zone.onStable.pipe(first()).subscribe(() => {
      this.zone.run(() => {
        this.getContainer(type).createEmbeddedView(template);
      });
    });
  }

  clearUI(type: ContainerType) {
    this.getContainer(type).clear();
  }

  private getContainerList(type: ContainerType): Array<ViewContainerRef> {
    if (!this.containerLists[type]) {
      this.containerLists[type] = [];
    }
    return this.containerLists[type];
  }

  private getContainer(type: ContainerType): ViewContainerRef {
    return this.getContainerList(type)[0];
  }
}
