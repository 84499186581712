import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from './local-storage.service';

export interface RecentEntry {
  id: string;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class RecentService {
  constructor(private localStorage: LocalStorageService) {}

  public list(category: string): RecentEntry[] {
    return this.localStorage.getObject(`recent_${category}`, []);
  }

  public add(category: string, id: string, data: any, maxCount?: number): void {
    if (!maxCount) {
      maxCount = 10;
    }
    const list = _.reject(this.list(category), { id });
    list.unshift({ id, data });
    this.localStorage.setItem(`recent_${category}`, _.slice(list, 0, maxCount));
  }
}
