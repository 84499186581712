import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ExportService } from './export.service';

type Callback = (data: any) => string;

class TextReader {
  private position: number;
  private length: number;
  constructor(private text: string) {
    this.position = 0;
    this.length = text.length;
  }

  public eof(): boolean {
    return this.position >= this.length;
  }

  public skipEmpty(): void {
    while (!this.eof() && /[ \n\r]/.test(this.peek())) {
      this.position++;
    }
  }
  public peek(): string {
    return this.text[this.position];
  }
  public read(): string {
    return this.text[this.position++];
  }
}

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  private readonly toEscape = /[,"\n]/;

  constructor(private exportService: ExportService) {}

  public readTextFile(blob: Blob): Observable<string> {
    return new Observable((obs) => {
      if (!(blob instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result.toString());
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });
  }

  public parseCSV(csv: string, hasHeader = true): { data: string[][]; header: string[] } {
    const data: string[][] = [];
    let header: string[] = [];

    const reader = new TextReader(csv);
    reader.skipEmpty();

    if (hasHeader && !reader.eof()) {
      header = this.parseRow(reader);
    }
    while (!reader.eof()) {
      data.push(this.parseRow(reader));
    }

    return { data, header };
  }

  private parseRow(reader: TextReader): string[] {
    const cells: string[] = [];
    let status: 'start' | 'escaped' | 'raw' | 'after' = 'start';
    while (!reader.eof()) {
      const test = reader.read();
      if (status === 'start' && test === ' ') {
        // Trim spaces
        continue;
      } else if (test === '\r') {
        continue;
      } else if (test === '\n' && status !== 'escaped') {
        break;
      }
      switch (status) {
        case 'start':
          if (test === '"') {
            status = 'escaped';
            cells.push('');
          } else if (test === ',') {
            cells.push('');
          } else {
            status = 'raw';
            cells.push(test);
          }
          break;
        case 'after':
          if (test === ',') {
            status = 'start';
          }
          break;
        case 'raw':
          if (test === ',') {
            status = 'start';
          } else {
            cells[cells.length - 1] += test;
          }
          break;
        case 'escaped':
          if (test === '"') {
            if (reader.peek() === '"') {
              cells[cells.length - 1] += test;
              reader.read();
            } else {
              status = 'after';
            }
          } else {
            cells[cells.length - 1] += test;
          }
          break;
      }
    }
    reader.skipEmpty();
    return _.map(cells, (cell) => _.trim(cell));
  }

  create(data: Array<any>, columns: Array<string | Callback>, headerRow?: Array<string>): string {
    const lines: Array<string> = [];

    if (headerRow) {
      lines.push(this.createRow(headerRow));
    }

    _.forEach(data, (line) => {
      lines.push(
        _.map(columns, (column) => {
          if (typeof column === 'string') {
            return this.escapeCell(this.exportService.defaultFormat(line, column));
          } else {
            return this.escapeCell(column(line));
          }
        }).join()
      );
    });

    return lines.join('\n') + '\n';
  }

  private createRow(cells: Array<string>): string {
    return _.map(cells, (cell) => this.escapeCell(cell)).join(',');
  }

  private escapeCell(cell: string): string {
    if (this.toEscape.test(cell)) {
      return `"${cell.replace(/"/g, '""')}"`;
    }
    return cell;
  }
}
