import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { User } from '../../models';

@Component({
  selector: 'core-choose-user',
  templateUrl: './choose-user.component.html',
  styleUrls: ['./choose-user.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChooseUserComponent,
      multi: true,
    },
  ],
})
export class ChooseUserComponent implements OnInit {
  @Input() label: string;
  @Input() orgId: string;

  public filteredUsers: Observable<User[]>;
  public control = new FormControl('');
  public onTouched = _.noop;
  private onChange = _.noop;
  private last: User | null;

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.filteredUsers = this.control.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((path) => this.queryUsers(path)),
      shareReplay(1)
    );

    this.control.valueChanges.subscribe((value) => {
      if (_.isObject(value)) {
        this.setValue(<User>value);
      } else if (!!this.last && this.display(this.last) !== value) {
        this.setValue(null);
      }
    });
  }

  private setValue(value: User | null): void {
    this.last = value;
    this.onChange(value);
  }

  public clear() {
    this.control.patchValue('');
  }

  public writeValue(User: User | null): void {
    this.control.setValue((this.last = User));
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public display(user?: User) {
    return user ? `${user['firstName']} ${user['lastName']}` : undefined;
  }

  public chooseMe() {
    this.apollo
      .query({
        query: gql`
          query {
            me {
              firstName
              lastName
              id
            }
          }
        `,
      })
      .subscribe((result) => {
        this.control.setValue(_.get(result, 'data.me'));
      });
  }

  private queryUsers(text: string): Observable<User[]> {
    return text.length >= 3
      ? this.apollo
          .query({
            query: gql`
              query User($orgId: ID!, $text: String!) {
                allUsers(orgId: $orgId, first: 10, text: $text) {
                  edges {
                    node {
                      firstName
                      lastName
                      code
                      id
                    }
                  }
                }
              }
            `,
            variables: { text, orgId: this.orgId },
          })
          .pipe(map((result) => _.map(_.get(result, 'data.allUsers.edges'), 'node')))
      : of([]);
  }
}
