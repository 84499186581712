import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorComponent } from '../components/error/error.component';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private snackbar: MatSnackBar, private dialog: MatDialog) {}

  public success(message: string) {
    this.open(message, 'success');
  }
  public error(message: string, error: unknown) {
    this.dialog.open(ErrorComponent, { data: { message, error } });
  }
  public info(message: string) {
    this.open(message, 'info');
  }
  public warning(message: string) {
    this.open(message, 'warning');
  }

  // public clear(message?: string) {
  // }

  private open(message: string, type: string) {
    this.snackbar.open(message, undefined, {
      panelClass: `toast-${type}`,
      duration: 2000,
    });
  }
}
