<mat-toolbar>
  <button mat-icon-button i18n-aria-label aria-label="Close navigation menu" (click)="app.toggleNavigation()">
    <mat-icon>arrow_left</mat-icon>
  </button>
  <span class="spacer"></span>
  <img class="logo" src="assets/logo.svg" aria-label="Adkom logo" i18n-aria-label />
</mat-toolbar>

<mat-nav-list>
  <ng-container *ngIf="rights.byType('BLIP')">
    <h5>Support</h5>
    <a mat-list-item [routerLink]="['/', orgId, 'support']" [routerLinkActive]="activeClass" i18n>Dashboard</a>
    <a mat-list-item [routerLink]="['/', orgId, 'support', 'proposal']" [routerLinkActive]="activeClass" i18n>
      Proposals
    </a>
    <a mat-list-item [routerLink]="['/', orgId, 'support', 'inventory']" [routerLinkActive]="activeClass" i18n>
      Inventory
    </a>
    <a mat-list-item [routerLink]="['/', orgId, 'support', 'locations']" [routerLinkActive]="activeClass" i18n>
      Locations
    </a>
  </ng-container>
</mat-nav-list>
<ng-container coreContainer="Navigation"></ng-container>
