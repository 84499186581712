<div #strings class="strings">
  <span id="airport">Airport</span>
  <span id="aquarium">Aquarium</span>
  <span id="bigBoxStore">Big Box Store</span>
  <span id="city">City</span>
  <span id="clothingStore">Clothing Store</span>
  <span id="electronicsStore">Electronics Store</span>
  <span id="food">Food</span>
  <span id="hospital">Hospital</span>
  <span id="intersection">Intersection</span>
  <span id="lightRailStation">Light Rail Station</span>
  <span id="metroStation">Metro Station</span>
  <span id="movieTheater">Movie Theater</span>
  <span id="museum">Museum</span>
  <span id="pharmacy">Pharmacy</span>
  <span id="shoppingMall">Shopping Mall</span>
  <span id="stadium">Stadium</span>
  <span id="supermarket">Supermarket</span>
  <span id="themePark">Theme Park</span>
  <span id="trainStation">Train Station</span>
  <span id="university">University</span>
  <span id="warehouseStore">Warehouse Store</span>
</div>
