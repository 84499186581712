import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { SESSION, Session } from '../../models';

@Component({
  selector: 'core-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Inject(SESSION) private session: Session) {}

  public formated: string;

  ngOnInit(): void {
    // Info for support, not translated intentionally
    const lines = [
      `Page: ${window.location}`,
      `User: ${this.session.user?.first_name} ${this.session.user?.last_name} (${this.session.user?.id})`,
      // `Organization: ${this.organization.value.name} (${this.organization.value.newId})`
    ];
    if (this.data.error.url) {
      lines.push(`Request: ${this.data.error.url}`);
    }

    _.forEach(['message', 'source', 'error', 'graphQLErrors[0]', 'networkError.error.errors[0].message'], (path) => {
      const error = _.get(this.data.error, path);
      if (_.isString(error)) {
        lines.push(error);
      } else if (error) {
        lines.push(JSON.stringify(error, undefined, 2));
      }
    });
    this.formated = lines.join('\n');
  }
}
