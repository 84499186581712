import { Component, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';
import { Organization } from '../../models';
import { OrganizationService } from '../../services';

@Component({
  selector: 'core-choose-organization',
  templateUrl: './choose-organization.component.html',
  styleUrls: ['./choose-organization.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ChooseOrganizationComponent,
      multi: true,
    },
  ],
})
export class ChooseOrganizationComponent implements OnInit {
  @Input() label: string;
  @Input() type: string;
  @Input() app: string;

  public filteredOrganizations: Observable<Organization[]>;
  public control = new FormControl('');
  public onTouched = _.noop;
  private onChange = _.noop;
  private last: Organization | null;

  constructor(private apollo: Apollo) {}

  ngOnInit() {
    this.filteredOrganizations = this.control.valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((path) => this.queryOrganizations(path)),
      shareReplay(1)
    );

    this.control.valueChanges.subscribe((value) => {
      if (_.isObject(value)) {
        this.setValue(<Organization>value);
      } else if (!!this.last && this.last.name !== value) {
        this.setValue(null);
      }
    });
  }

  private setValue(value: Organization | null): void {
    this.last = value;
    this.onChange(value);
  }

  public clear() {
    this.control.patchValue('');
  }

  public writeValue(organization: Organization | null): void {
    this.control.setValue((this.last = organization));
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  public display(organization?: Organization) {
    return organization ? organization.name : undefined;
  }

  private queryOrganizations(text: string): Observable<Organization[]> {
    return this.apollo
      .query({
        query: gql`
          query Organization($text: String, $type: [String], $enabled: [String]) {
            allOrganizations(first: 10, text: $text, typeIn: $type, enabled: $enabled) {
              edges {
                node {
                  name
                  code
                  id
                }
              }
            }
          }
        `,
        variables: {
          text: text ? text : undefined,
          enabled: this.app ? [this.app] : undefined,
          type: this.type ? [this.type] : undefined,
        },
      })
      .pipe(map((result) => _.map(_.get(result, 'data.allOrganizations.edges'), 'node')));
  }
}
