import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable, of, throwError, timer, concat } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { PageInfo } from '../models';
import { StoredQuery } from '../models/stored-query';
import { SignService } from './sign.service';

@Injectable({
  providedIn: 'root',
})
export class StoredQueryService {
  constructor(private apollo: Apollo, private signService: SignService) {}

  public get(id: string): Observable<StoredQuery> {
    return this.apollo
      .query({
        query: gql`
          query getQuery($id: ID!) {
            storedQuery(id: $id) {
              id
              name
              updated
              count
              distance
              enabled
              pointsOfInterest {
                latitude
                longitude
                distance
                name
              }
              postalCodes {
                postalCode
                name
              }
              units {
                edges {
                  node {
                    name
                    id
                    latitude
                    longitude
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .pipe(
        map((result) => {
          return this.fromServer(_.get(result, 'data.storedQuery'));
        })
      );
  }

  public list(
    query: any = {},
    pageSize = 100,
    before?: string,
    after?: string,
    order?: string
  ): Observable<{
    queries: StoredQuery[];
    pageInfo: PageInfo;
    totalCount: number;
  }> {
    return this.apollo
      .query({
        query: gql`
          query StoredQueries(
            $first: Int
            $last: Int
            $after: String
            $before: String
            $orgId: ID
            $source: String
            $name: String
            $order: String
          ) {
            allStoredQueries(
              first: $first
              last: $last
              after: $after
              before: $before
              orgId: $orgId
              source: $source
              name: $name
              orderBy: $order
            ) {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  id
                  name
                  count
                  updated
                }
              }
            }
          }
        `,
        variables: _.omitBy(
          _.assign(
            {
              first: before ? undefined : pageSize,
              last: !before ? undefined : pageSize,
              after,
              before,
              order,
            },
            query
          ),
          _.isNil
        ),
      })
      .pipe(
        map((result) => {
          const allQueries = _.get(result, 'data.allStoredQueries', {});
          const queries = _.map(allQueries.edges, 'node');
          return {
            queries,
            totalCount: allQueries.totalCount,
            pageInfo: allQueries.pageInfo,
          };
        })
      );
  }

  public create(orgId: string, query: StoredQuery): Observable<StoredQuery> {
    // Create empty to make sure we don't time out on the create
    // Then call update which handles the time out
    return this.apollo
      .mutate({
        mutation: gql`
          mutation createStoredQuery($query: CreateStoredQueryInput) {
            createStoredQueries(input: { storedQueries: [$query] }) {
              storedQueries {
                id
                updated
              }
            }
          }
        `,
        variables: {
          query: _.assign({ orgId }, this.toServer(query), {
            pointsOfInterest: [],
            postalCodes: [],
          }),
        },
      })
      .pipe(
        switchMap((result) => {
          query.id = _.get(result, 'data.createStoredQueries.storedQueries[0].id');
          const updated = _.get(result, 'data.createStoredQueries.storedQueries[0].updated');
          return this.update(query, true, updated);
        })
      );
  }

  public update(query: Partial<StoredQuery>, process: boolean, updated: string): Observable<StoredQuery> {
    // This call may take longer than 30 seconds, if it fails keep retrying until updated changes
    return this.apollo
      .mutate({
        mutation: gql`
          mutation updateStoredQuery($query: UpdateStoredQueryInput, $process: Boolean) {
            updateStoredQueries(input: { storedQueries: [$query], process: $process }) {
              storedQueries {
                id
                name
                updated
                count
                units {
                  edges {
                    node {
                      name
                      id
                      latitude
                      longitude
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          query: _.assign({ id: query.id }, this.toServer(query)),
          process,
        },
      })
      .pipe(
        map((result) => this.fromServer(_.get(result, 'data.updateStoredQueries.storedQueries[0]'))),
        catchError((error) => {
          // If the request timed out, try getting it
          if (_.startsWith(error.message, 'Network error:')) {
            return concat(
              timer(0, 15000).pipe(
                take(20),
                mergeMap(() => this.get(query.id)),
                filter((test) => test.updated > updated)
              ),
              throwError('Timeout waiting for query to update')
            ).pipe(take(1));
          } else {
            return throwError(error);
          }
        })
      );
  }
  public remove(id: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation deleteStoredQuery($id: ID) {
            deleteStoredQueries(input: { storedQueries: [$id] }) {
              storedQueries {
                id
              }
            }
          }
        `,
        variables: { id },
      })
      .pipe(map(() => true));
  }

  public distance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const EARTH_RADIUS_MILES = 3958.8;
    const radians = (deg: number) => (deg / 180) * Math.PI;
    return (
      Math.acos(
        Math.sin(radians(lat1)) * Math.sin(radians(lat2)) +
          Math.cos(radians(lat1)) * Math.cos(radians(lat2)) * Math.cos(radians(lon1 - lon2))
      ) * EARTH_RADIUS_MILES
    );
  }

  private fromServer(serverObject: any): StoredQuery {
    serverObject.units = _.map(_.get(serverObject, 'units.edges'), 'node');
    return serverObject;
  }
  private toServer(query: Partial<StoredQuery>): any {
    const serverObject: any = _.pick(query, ['name', 'distance', 'pointsOfInterest', 'postalCodes', 'enabled']);
    return serverObject;
  }
}
