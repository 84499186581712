import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CellParser } from '../classes/cell-parser';
import { PageInfo } from '../models';
import { Message, Proposal, ProposalColumn } from '../models/proposal';

@Injectable({
  providedIn: 'root',
})
export class ProposalService {
  constructor(private apollo: Apollo) {}

  public list(
    query: any,
    pageSize = 100,
    before?: string,
    after?: string,
    order?: string
  ): Observable<{
    proposals: Proposal[];
    pageInfo: PageInfo;
    totalCount: number;
  }> {
    return this.apollo
      .query({
        query: gql`
          query Proposals(
            $first: Int
            $last: Int
            $after: String
            $before: String
            $orgId: ID
            $end: Date
            $name: String
            $owner: ID
            $state: [String]
            $order: String
          ) {
            allProposals(
              first: $first
              last: $last
              after: $after
              before: $before
              orgId: $orgId
              name: $name
              end: $end
              salesRep: $owner
              stateIn: $state
              orderBy: $order
            ) {
              totalCount
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
              edges {
                node {
                  id
                  code
                  name
                  start
                  end
                  iconUrl
                  dueDate
                  owner: salesRep {
                    id
                    firstName
                    lastName
                  }
                  state
                  tasks {
                    state
                    task
                    completed
                  }
                }
              }
            }
          }
        `,
        variables: _.omitBy(
          _.assign(
            {
              first: before ? undefined : pageSize,
              last: !before ? undefined : pageSize,
              after,
              before,
              order,
            },
            query
          ),
          _.isNil
        ),
      })
      .pipe(
        map((result) => {
          const allProposals = _.get(result, 'data.allProposals', {});
          const proposals = _.map(allProposals.edges, 'node');
          return {
            proposals,
            totalCount: allProposals.totalCount,
            pageInfo: allProposals.pageInfo,
          };
        })
      );
  }

  get(id: string): Observable<Proposal> {
    return this.apollo
      .query({
        query: gql`
          query Proposal($id: ID!) {
            proposal(id: $id) {
              id
              code
              state
              created
              modified
              name
              start
              end
              iconUrl
              agency: agencyOrg {
                id
                name
              }
              client
              owner: salesRep {
                id
                firstName
                lastName
              }
              anonymizeClient
              description
              location {
                id
                name
              }
              history {
                action
                date
                by {
                  id
                  firstName
                  lastName
                }
                signOwner {
                  id
                  name
                }
                units(first: 25) {
                  totalCount
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
              units {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              holds {
                edges {
                  node {
                    unitId
                    unit {
                      id
                      name
                    }
                    rate
                    start
                    end
                    bonusPeriods
                    productionCost
                    installationCost
                    expiration
                    notes
                  }
                }
              }
              contracts {
                edges {
                  node {
                    unitId
                    unit {
                      id
                      name
                    }
                    rate
                    start
                    end
                    bonusPeriods
                    productionCost
                    installationCost
                    notes
                  }
                }
              }
              columns {
                name
                column
                data
              }
              sort {
                column
                group
              }
              artwork {
                url
                color
              }
              photoSheet {
                url
                created
                googleSheetId
              }
              dueDate
              dueDateTimezone
              query
              state
              tasks {
                state
                task
                completed
              }
              cancelReason
            }
          }
        `,
        variables: { id },
        errorPolicy: 'all',
      })
      .pipe(
        map((result) => _.get(result, 'data.proposal')),
        tap((proposal) => {
          proposal.units = _.map(_.get(proposal, 'units.edges'), 'node');
          proposal.holds = _.map(_.get(proposal, 'holds.edges'), 'node');
          proposal.contracts = _.map(_.get(proposal, 'contracts.edges'), 'node');
          _.forEach(proposal.history, (entry) => {
            entry.unitCount = _.get(entry, 'units.totalCount', 0);
            entry.units = _.map(_.get(entry, 'units.edges'), 'node');
          });
        })
      );
    // query: any;
  }

  save(proposal: Proposal): Observable<Proposal> {
    if (!proposal.id) {
      proposal.state = 'InventoryEvaluation';
      return this.apollo
        .mutate({
          mutation: gql`
            mutation createProposal($proposals: [CreateProposalInput]!) {
              createProposals(input: { proposals: $proposals }) {
                proposals {
                  id
                  created
                  modified
                  artwork {
                    url
                    color
                  }
                  history {
                    action
                    date
                    by {
                      id
                      firstName
                      lastName
                    }
                    signOwner {
                      id
                      name
                    }
                    units {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { proposals: [this.toServer(proposal)] },
        })
        .pipe(map((result) => _.get(result, 'data.createProposals.proposals.0')));
    } else {
      return this.apollo
        .mutate({
          mutation: gql`
            mutation updateProposal($proposals: [UpdateProposalInput]!) {
              updateProposals(input: { proposals: $proposals }) {
                proposals {
                  id
                  created
                  modified
                  artwork {
                    url
                    color
                  }
                  history {
                    action
                    date
                    by {
                      id
                      firstName
                      lastName
                    }
                    signOwner {
                      id
                      name
                    }
                    units {
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: { proposals: [this.toServer(proposal, true)] },
        })
        .pipe(map((result) => _.get(result, 'data.updateProposals.proposals.0')));
    }
  }

  public createMessage(message: any): Observable<Message> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation createMessage($message: CreateMessageInput) {
            createMessages(input: { messages: [$message] }) {
              messages {
                id
                modified
                notes
                recipient {
                  id
                }
              }
            }
          }
        `,
        variables: { message },
      })
      .pipe(map((result) => _.get(result, 'data.createMessages.messages.0')));
  }

  public updateMessage(message: Partial<Message>): Observable<Message> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation updateMessage($message: UpdateMessageInput) {
            updateMessages(input: { messages: [$message] }) {
              messages {
                id
                modified
                notes
              }
            }
          }
        `,
        variables: { message },
      })
      .pipe(map((result) => _.get(result, 'data.updateMessages.messages.0')));
  }

  public listMessages(id: string): Observable<Message[]> {
    return this.apollo
      .query({
        query: gql`
          query messages($id: ID!) {
            proposal(id: $id) {
              messages {
                totalCount
                edges {
                  node {
                    id
                    recipient {
                      id
                      name
                    }
                    modified
                    type
                    notes
                    avails {
                      units {
                        unit {
                          id
                          name
                        }
                        available
                      }
                    }
                    holds {
                      unitId
                      expiration
                      start
                      end
                      rate
                      bonusPeriods
                      productionCost
                      installationCost
                      internalProduction
                      feeRate
                      notes
                    }
                    contracts {
                      unitId
                      start
                      end
                      rate
                      bonusPeriods
                      productionCost
                      installationCost
                      internalProduction
                      feeRate
                      notes
                    }
                    contract {
                      acceptedOn
                      acceptedBy {
                        firstName
                        lastName
                      }
                    }
                    replies {
                      id
                      recipient {
                        id
                        name
                      }
                      modified
                      type
                      notes
                      avails {
                        units {
                          id
                          available
                          start
                          end
                          notes
                        }
                      }
                      holds {
                        unitId
                        expiration
                        start
                        end
                        rate
                        bonusPeriods
                        productionCost
                        installationCost
                        internalProduction
                        feeRate
                        notes
                        state
                      }
                      replies {
                        id
                        recipient {
                          id
                          name
                        }
                        modified
                        type
                        notes
                        id
                        recipient {
                          id
                          name
                        }
                        modified
                        type
                        notes
                        avails {
                          units {
                            id
                            available
                            start
                            end
                          }
                        }
                        holds {
                          unitId
                          expiration
                          start
                          end
                          rate
                          bonusPeriods
                          productionCost
                          installationCost
                          internalProduction
                          feeRate
                          notes
                          state
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .pipe(map((result) => _.map(_.get(result, 'data.proposal.messages.edges'), 'node')));
  }

  private toServer(proposal: Proposal, update = false): any {
    const serverData: any = _.pick(proposal, [
      'id',
      'name',
      'orgId',
      'start',
      'end',
      'state',
      'description',
      'agency',
      'client',
      'anonymizeClient',
      'iconUrl',
      'location',
      'columns',
      'sort',
      'artwork',
      'tasks',
      'query',
      'dueDate',
      'dueDateTimezone',
      'holds',
      'contracts',
      'photoSheet',
    ]);
    if (update) {
      delete serverData.orgId;
    } else {
      delete serverData.id;
    }
    if (proposal.owner) {
      serverData.salesRep = proposal.owner.id;
    }
    if (proposal.agency) {
      serverData.agencyId = proposal.agency.id;
      delete serverData.agency;
    }
    serverData.unitIds = _.map(proposal.units, 'id');
    if (serverData.query) {
      serverData.query = JSON.stringify(serverData.query);
    }
    if (serverData.holds) {
      serverData.holds = _.map(serverData.holds, (hold) => _.omit(hold, ['unit', '__typename', 'state']));
    }
    if (serverData.contracts) {
      serverData.contracts = _.map(serverData.contracts, (contract) =>
        _.omit(contract, ['unit', '__typename', 'state'])
      );
    }
    return serverData;
  }

  public listAgencyProposalColumns(orgId: string, agencyName: string): Observable<ProposalColumn[]> {
    return this.apollo
      .query({
        query: gql`
          query AgencyProposalColumns($orgId: ID!, $agencyName: String, $order: String) {
            allProposals(orgId: $orgId, agency: $agencyName, orderBy: $order) {
              edges {
                node {
                  columns {
                    name
                    column
                    data
                  }
                }
              }
            }
          }
        `,
        variables: { orgId, agencyName, order: '-modified' },
      })
      .pipe(
        map((result) => {
          const proposals = _.get(result, 'data.allProposals', null);
          if (proposals) {
            return _.chain(proposals.edges)
              .map('node')
              .map('columns')
              .flatten()
              .uniqBy((c) => [c.name, c.column, c.data].join())
              .sortBy('name')
              .value();
          } else {
            return [];
          }
        })
      );
  }

  public validateExpression(control: AbstractControl): ValidationErrors | null {
    const expression = control.value;
    if (!_.startsWith(expression, '=')) {
      return null;
    }
    const error = new CellParser(expression, {}).validate();
    if (error) {
      return { expression: error };
    }
    return null;
  }
}
