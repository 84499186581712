import { formatDate, formatNumber } from '@angular/common';
import * as _ from 'lodash';
import * as moment from 'moment';
import { formatFeet, formatInches, formatLat, formatLon, formatMeters } from '../services/distance';

/* This is the grammar of the expressions we handle

  EXPRESSION     = BOOLEAN
  BOOLEAN        = SUM (COMPARE SUM) ?
  SUM            = PRODUCT (ADDITION PRODUCT) *
  PRODUCT        = PRIMARY (MULTIPLICATION PRIMARY) *
  PRIMARY        = '(' EXPRESSION ')' | CONST | VARIABLE | FUNCTION
  FUNCTION       = FUNCTIONNAME START EXPRESSION (DELIM EXPRESSION)* END

  COMPARE        = '=' | '<>' | '<' | '<=' | '>' | '>='
  ADDITION       = '+' | '-'
  MULTIPLICATION = '*' | '/'
  CONST          = NUMBER | STRING
  NUMBER         = -?[0-9]+(.[0-9]+)?
  STRING         = "[^"|\"]*"
  VARIABLE       = ID(.ID)*
  ID             = [a-zA-Z][a-zA-Z0-9]*
  FUNCTIONNAME   = 'IF' | 'ISBLANK' | 'FORMAT' | 'ISEQUAL' | 'DAYS' | 'CONCAT'
  START          = '('
  END            = ')'
  DELIM          = ','

  Empty values returns '' or 0 depending on the expression
*/

enum Tokens {
  ADDITION,
  MULTIPLICATION,
  CONST,
  VARIABLE,
  FUNCTIONNAME,
  START,
  END,
  DELIM,
  COMPARE,
  INVALID,
  EMPTY,
}

interface Token {
  token: Tokens;
  value: string | number | boolean;
}

const parts = [
  '(IF|ISBLANK|FORMAT|ISEQUAL|DAYS|CONCAT|MROUND|DATEADD)', // FUNCTIONNAME
  '(-?[0-9]+(?:\\.[0-9]+)?)', // NUMBER
  '([\\+\\-])', // ADDITION
  '([\\*\\/])', // MULTIPLICATION
  '("(?:[^"\\\\]|\\\\["\\\\])*")', // STRING
  '([a-zA-Z][a-zA-Z0-9]*(?:\\([^\\)]*\\))?(?:\\.[a-zA-Z][a-zA-Z0-9]*)*)', // VARIABLE
  '(\\()', // START
  '(\\))', // END
  '(,)', // DELIM
  '(=|<>|<=|<|>=|>)', // COMPARE
];

export class CellParser {
  private cursor: number;
  private lastToken: Token;
  private locale: string;
  private whiteSpace = new RegExp('^ *');
  private tokenizer = new RegExp(`^(?:${_.join(parts, '|')})`);
  private parameters = new RegExp('\\(([^\\)]*)\\)');

  constructor(private program: string = '', private data: Record<string, any> = {}) {
    // Skip the = sign at the start
    this.cursor = 1;
  }

  public evaluate(locale: string): string {
    try {
      this.locale = locale;
      const result = this.expression();
      const checkExtra = this.getNextToken();
      if (checkExtra.token !== Tokens.EMPTY) {
        return `Extra token found: ${checkExtra.value}`;
      }
      return _.isNil(result.value) ? '' : result.value.toString();
    } catch (err) {
      return err.message;
    }
  }

  public validate(): string | null {
    try {
      this.locale = 'en-us';
      this.expression();
      const checkExtra = this.getNextToken();
      if (checkExtra.token !== Tokens.EMPTY) {
        throw new Error('Extra token');
      }
      return null;
    } catch (err) {
      return `${err.message} at column ${this.cursor}`;
    }
  }

  private expression(): Token {
    return this.boolean();
  }

  private boolean() {
    const left = this.sum();

    const op = this.getNextToken();
    if (op.token === Tokens.COMPARE) {
      const right = this.sum();
      return this.compare(left.value, op.value.toString(), right.value);
    } else {
      this.ungetToken(op);
    }

    return left;
  }

  private sum(): Token {
    let addend = this.product();
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let op = this.getNextToken();
      if (op.token === Tokens.CONST && _.isNumber(op.value) && op.value < 0) {
        // Convert from unary minus to binary
        op.value = -op.value;
        this.ungetToken(op);
        op = { token: Tokens.ADDITION, value: '-' };
      }
      if (op.token === Tokens.ADDITION) {
        addend = this.evaluateOperator(addend.value, op.value.toString(), this.product().value);
      } else {
        this.ungetToken(op);
        break;
      }
    }
    return addend;
  }

  private product(): Token {
    let factor = this.primary();
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const op = this.getNextToken();
      if (op.token === Tokens.MULTIPLICATION) {
        factor = this.evaluateOperator(factor.value, op.value.toString(), this.primary().value);
      } else {
        this.ungetToken(op);
        break;
      }
    }
    return factor;
  }

  private primary(): Token {
    const first = this.getNextToken();

    switch (first.token) {
      case Tokens.CONST:
        return first;
      case Tokens.VARIABLE: {
        return {
          token: Tokens.CONST,
          value: _.get(this.data, this.getParameterizedName(first.value.toString())),
        };
      }
      case Tokens.FUNCTIONNAME:
        return this.evaluateFunction(first.value.toString());
      case Tokens.START: {
        const expression = this.expression();
        this.expect(Tokens.END, ')');
        return expression;
      }
    }
    throw new Error(first.token === Tokens.EMPTY ? 'Expected more tokens' : `Invalid token: ${first.value}`);
  }

  private expect(token: Tokens, symbol: string) {
    const next = this.getNextToken();
    if (next.token !== token) {
      throw new Error(`Expected ${symbol}`);
    }
  }

  private evaluateFunction(name: string): Token {
    this.expect(Tokens.START, '(');

    let token: Token = { token: Tokens.INVALID, value: 'Unknown function' };
    switch (name) {
      case 'IF':
        token = this.evaluateIf();
        break;
      case 'FORMAT':
        token = this.evaluateFormat();
        break;
      case 'DAYS':
        token = this.evaluateDays();
        break;
      case 'ISBLANK':
        token = this.evaluateIsBlank();
        break;
      case 'ISEQUAL':
        token = this.evaluateIsEqual();
        break;
      case 'CONCAT':
        token = this.evaluateConcat();
        break;
      case 'MROUND':
        token = this.evaluateMRound();
        break;
      case 'DATEADD':
        token = this.evaluateDateAdd();
        break;
    }

    this.expect(Tokens.END, ')');
    return token;
  }

  public extractFields(): string[] {
    const fields = new Set<string>();
    for (
      let token = this.getNextToken();
      ![Tokens.EMPTY, Tokens.INVALID].includes(token.token);
      token = this.getNextToken()
    ) {
      if (token.token === Tokens.VARIABLE) {
        const name = token.value.toString();
        const alias = this.getParameterizedAlias(name);
        fields.add(alias ? `${alias}:${name}` : name);
      }
    }
    return Array.from(fields);
  }

  private getParameterizedAlias(name: string): string | null {
    const matches = this.parameters.exec(name);
    if (!matches) {
      return null;
    }
    const parts = [name.substr(0, matches.index)];
    _.forEach(_.split(matches[1], ','), (part) => {
      parts.push(_.replace(_.trim(_.last(_.split(part, ':')), ' "'), /[^a-zA-Z0-9]/g, '_'));
    });
    return _.join(parts, '_');
  }

  private getParameterizedName(name: string): string {
    const alias = this.getParameterizedAlias(name);
    if (!alias) {
      return name;
    }
    const matches = this.parameters.exec(name);
    return alias + name.substring(matches.index + matches[0].length);
  }

  private evaluateIf(): Token {
    const cond = this.expression();
    this.expect(Tokens.DELIM, ',');
    const yesValue = this.expression();
    this.expect(Tokens.DELIM, ',');
    const noValue = this.expression();
    return cond.value ? yesValue : noValue;
  }
  private evaluateIsBlank(): Token {
    const a = this.expression();
    return {
      token: Tokens.CONST,
      value: _.isNil(a.value) || (_.isEmpty(a.value) && !_.isFinite(a.value)),
    };
  }
  private evaluateIsEqual(): Token {
    const a = this.expression();
    this.expect(Tokens.DELIM, ',');
    const b = this.expression();
    return { token: Tokens.CONST, value: _.isEqual(a.value, b.value) };
  }

  private evaluateFormat(): Token {
    const value = this.expression();
    this.expect(Tokens.DELIM, ',');
    const format = this.expression();

    if (_.isNil(value.value)) {
      return { token: Tokens.CONST, value: null };
    }

    const [type, pattern] = _.split(format.value.toString(), ':', 2);

    const numberValue = _.isNumber(value.value) ? value.value : parseFloat(value.value as string);
    let formatted: string;
    switch (type) {
      case 'NUM':
        formatted = formatNumber(numberValue, this.locale, pattern);
        break;
      case 'DIS':
        switch (pattern) {
          case 'LAT':
            formatted = formatLat(this.locale, numberValue);
            break;
          case 'LON':
            formatted = formatLon(this.locale, numberValue);
            break;
          case 'FT':
            formatted = formatFeet(this.locale, numberValue);
            break;
          case 'IN':
            formatted = formatInches(this.locale, numberValue);
            break;
          case 'M':
            formatted = formatMeters(this.locale, numberValue);
            break;
        }
        break;
      case 'DATE':
        formatted = formatDate(value.value as any, pattern, this.locale);
        break;
      default:
        throw new Error(`Invalid format pattern: ${format.value}`);
    }
    return { token: Tokens.CONST, value: formatted };
  }

  private evaluateDays(): Token {
    const end = this.expression();
    this.expect(Tokens.DELIM, ',');
    const start = this.expression();

    const endDate = moment(end.value as any);
    const startDate = moment(start.value as any);
    return {
      token: Tokens.CONST,
      value: endDate.diff(startDate, 'days', false),
    };
  }

  private evaluateConcat(): Token {
    let expressionValue = this.expression().value;
    let value = _.isNil(expressionValue) ? '' : expressionValue.toString();
    let next = this.getNextToken();
    while (next.token === Tokens.DELIM) {
      expressionValue = this.expression().value;
      value += _.isNil(expressionValue) ? '' : expressionValue.toString();
      next = this.getNextToken();
    }
    this.ungetToken(next);
    return { token: Tokens.CONST, value };
  }

  private evaluateDateAdd(): Token {
    const start = this.expression().value;
    this.expect(Tokens.DELIM, ',');
    const amount = this.expression().value;
    this.expect(Tokens.DELIM, ',');
    const unit = this.expression().value;

    if (_.isNil(start)) {
      return { token: Tokens.CONST, value: null };
    }
    return { token: Tokens.CONST, value: <any>moment(start as any)
        .add(amount as any, unit as any)
        .toDate() };
  }

  private evaluateMRound(): Token {
    let number = this.expression().value;
    this.expect(Tokens.DELIM, ',');
    let multiple = this.expression().value;

    if (_.isNil(number)) {
      return { token: Tokens.CONST, value: null };
    }
    number = _.isNumber(number) ? number : parseFloat(number as string);
    multiple = _.isNumber(multiple) ? multiple : parseFloat(multiple as string);
    const result = Math.round(number / multiple) * multiple;
    return { token: Tokens.CONST, value: _.isFinite(result) ? result : null };
  }

  private compare(lhs: string | number | boolean, op: string, rhs: string | number | boolean): Token {
    let result = false;
    switch (op) {
      case '=':
        result = lhs === rhs;
        break;
      case '<>':
        result = lhs !== rhs;
        break;
      case '<':
        result = lhs < rhs;
        break;
      case '<=':
        result = lhs <= rhs;
        break;
      case '>':
        result = lhs > rhs;
        break;
      case '>=':
        result = lhs >= rhs;
        break;
    }
    return { token: Tokens.CONST, value: result };
  }

  private evaluateOperator(lhs: string | number | boolean, op: string, rhs: string | number | boolean): Token {
    const l = _.isNumber(lhs) ? lhs : parseFloat(`${lhs}`);
    const r = _.isNumber(rhs) ? rhs : parseFloat(`${rhs}`);
    let result = 0;
    switch (op) {
      case '+':
        result = l + r;
        break;
      case '-':
        result = l - r;
        break;
      case '*':
        result = l * r;
        break;
      case '/':
        result = l / r;
        break;
    }
    return { token: Tokens.CONST, value: _.isFinite(result) ? result : null };
  }

  private getNextToken(): Token {
    if (this.lastToken) {
      const token = this.lastToken;
      delete this.lastToken;
      return token;
    }

    if (this.cursor < this.program.length) {
      const whiteSpace = this.whiteSpace.exec(this.program.substring(this.cursor));
      this.cursor += whiteSpace[0].length;
    }
    if (this.cursor < this.program.length) {
      const matches = this.tokenizer.exec(this.program.substring(this.cursor));
      if (!matches) {
        return { token: Tokens.INVALID, value: '' };
      } else {
        this.cursor += matches[0].length;
        const order = [
          Tokens.FUNCTIONNAME,
          Tokens.CONST,
          Tokens.ADDITION,
          Tokens.MULTIPLICATION,
          Tokens.CONST,
          Tokens.VARIABLE,
          Tokens.START,
          Tokens.END,
          Tokens.DELIM,
          Tokens.COMPARE,
        ];
        for (let index = 0; index < order.length; index++) {
          const token = order[index];
          let value = matches[index + 1];
          if (!_.isNil(value)) {
            if (index === 1) {
              // Number parse it here
              return { token, value: parseFloat(value) };
            }
            if (index === 4) {
              // String, remove quotes and encoding
              value = value
                .substr(1, value.length - 2)
                .replace(/\\\\/g, '\\')
                .replace(/\\"/g, '"');
            }
            return { token, value };
          }
        }
      }
    } else {
      return { token: Tokens.EMPTY, value: '' };
    }
  }

  private ungetToken(token: Token) {
    this.lastToken = token;
  }
}
