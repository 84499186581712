<mat-menu #userMenu="matMenu">
  <mat-nav-list>
    <a target="usermanual" href="https://acme-resources.s3.amazonaws.com/docs/Adkom+User+Manual.pdf" mat-list-item>
      <mat-icon>help_center</mat-icon>
      &nbsp;
      <ng-container i18n>Help</ng-container>
    </a>
    <a mat-list-item (click)="openAbout()">
      <mat-icon>info</mat-icon>
      &nbsp;
      <ng-container i18n>About</ng-container>
    </a>
    <mat-divider></mat-divider>
    <a mat-list-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      &nbsp;
      <ng-container i18n>Logout</ng-container>
    </a>
  </mat-nav-list>
</mat-menu>

<mat-toolbar *ngIf="session.isLoggedIn" color="primary">
  <button mat-icon-button i18n-aria-label aria-label="Toggle navigation menu" (click)="app.toggleNavigation()">
    <mat-icon>menu</mat-icon>
  </button>
  <span class="spacer title">{{ pageTitle | async }}</span>
  <ng-container coreContainer="Toolbar"></ng-container>
  <!-- <button mat-icon-button
      matTooltip="Quick navigate (F1)" i18n-matTooltip (click)="quickNavigate()">
      <mat-icon>explore</mat-icon>
  </button> -->
  <button mat-button [matMenuTriggerFor]="userMenu">
    <mat-icon>person</mat-icon>
    <span class="large">{{ session.user.first_name }} {{ session.user.last_name }}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-toolbar>

<ng-template #about>
  <h3 matDialogTitle i18n>About Adkom</h3>
  <mat-dialog-content>
    <p>{{ version.major }}.{{ version.minor }}.{{ version.patch }}.{{ version.build }}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose i18n>Close</button>
  </mat-dialog-actions>
</ng-template>
