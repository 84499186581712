import { Directive, ElementRef, Inject, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PAGETITLE } from '../models';

@Directive({
  selector: '[coreTitle]',
})
export class TitleDirective implements OnInit {
  constructor(private el: ElementRef, @Inject(PAGETITLE) private pageTitle: BehaviorSubject<string>) {}

  ngOnInit() {
    this.el.nativeElement.style.display = 'none';
    this.pageTitle.next(this.el.nativeElement.innerText);
  }
}
